import { useContext, useEffect } from "react";
// import { authAxios } from "../../framework/api";
// import { loginTokenKey, setItem } from "../../utils/localStorage";
import { AuthStateContext } from "../../contexts/AuthStateContext";

export default function AppNaverRedirectPage() {
  // const { handleChangeAuthState } = useContext(AuthStateContext);

  // useEffect(() => {
  //   const loadNaverLogin = () => {
  //     const naverLogin = new window.naver.LoginWithNaverId({
  //       clientId: process.env.REACT_APP_NAVER_KEY,
  //       callbackUrl: `${process.env.REACT_APP_ROOT_URL}/app/login/naver/redirect`,
  //       callbackHandle: true,
  //       isPopup: false,
  //       loginButton: { color: "green", type: 3, height: 30 },
  //     });

  //     naverLogin.init();
  //   };

  //   if (!document.getElementById("naver-login-sdk")) {
  //     const naverScript = document.createElement("script");
  //     naverScript.id = "naver-login-sdk";
  //     naverScript.src =
  //       "https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js";
  //     naverScript.type = "text/javascript";
  //     document.head.appendChild(naverScript);

  //     naverScript.onload = () => {
  //       if (window.naver) {
  //         loadNaverLogin();
  //       } else {
  //         console.error("Naver SDK 로드 실패");
  //       }
  //     };
  //   } else if (window.naver) {
  //     loadNaverLogin();
  //   }
  // }, []);
  useEffect(() => {
    console.log("naverLogin");
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-screen">
      loading...
    </div>
  );
}
