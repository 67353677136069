interface CustomButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  text: string;
  className: string;
  primaryType: boolean;
}

export default function CustomButton({
  text,
  className,
  primaryType,
  ...buttonProps
}: CustomButtonProps) {
  return (
    <button
      {...buttonProps}
      style={{
        boxShadow: primaryType ? "none" : "0 0 0 2px #9D7FDE inset",
        backgroundColor: primaryType ? "#9D7FDE" : "white",
        color: primaryType ? "white" : "#9D7FDE",
      }}
      className={`${className} rounded-[4px] sm:rounded-md text-xs sm:text-base`}
    >
      {text}
    </button>
  );
}

export function CustomButton2({
  text,
  className,
  primaryType,
  ...buttonProps
}: CustomButtonProps) {
  return (
    <button
      style={{
        boxShadow: primaryType ? "none" : "0 0 0 2px #9D7FDE inset",
        // backgroundColor: primaryType ? "#9D7FDE" : "white",
        color: primaryType ? "white" : "#9D7FDE",
      }}
      className={`${className} rounded-[4px] sm:rounded-md text-xs sm:text-base`}
      {...buttonProps}
    >
      {text}
    </button>
  );
}
