import { GENDERTYPE, SaveUser } from "../contexts/SaveDataContext";
import { getHMString, getYMDString } from "./timeConvert";

export function setInitUser(genderType: GENDERTYPE): SaveUser {
  return {
    nickName: null,
    birthday: getYMDString(new Date()),
    time: getHMString(new Date()),
    birthDayType: "SOLAR",
    genderType: genderType,
    unknownTime: false,
  };
}
