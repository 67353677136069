import { Navigate, Route, Routes } from "react-router-dom";
import CompatiblePage from "./pages/CompatiblePage";
import KakaoLoginPage from "./pages/KakaoRedirectPage";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import MyPage from "./pages/MyPage";
import AppMainPage from "./pages/app/MainPage";
import AppCompatiblePage from "./pages/app/CompatiblePage";
import AppLoginPage from "./pages/app/LoginPage";
import AppMyPage from "./pages/app/MyPage";
import AppKakaoLoginPage from "./pages/app/KakaoRedirectPage";
import AppNaverRedirectPage from "./pages/app/NaverRedirectPage";
// import { useEffect } from "react";
// import { getSaju } from "./framework/api";

function App() {
  // useEffect(() => {
  //   const updateVisitDay = () => {
  //     const storedVisitDay = localStorage.getItem("visitDay");
  //     const today = new Date();
  //     const todayDateStr = today.toISOString().split("T")[0];

  //     if (storedVisitDay) {
  //       const storedDateStr = new Date(storedVisitDay)
  //         .toISOString()
  //         .split("T")[0];

  //       if (storedDateStr < todayDateStr) {
  //         localStorage.setItem("visitDay", todayDateStr);
  //         console.log("today you visit this page first");
  //         // API 호출
  //         getSaju.post("/mobile/visit").catch((error) => console.log(error));
  //       } else {
  //         console.log("today you visit this page before");
  //       }
  //     } else {
  //       localStorage.setItem("visitDay", todayDateStr);
  //       console.log("today you visit this page first");
  //       getSaju.post("/mobile/visit").catch((error) => console.log(error));
  //     }
  //   };

  //   updateVisitDay();
  // }, []);
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/compatible" element={<CompatiblePage />} />
      <Route path="/MyPage" element={<MyPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/login/kakao/redirect" element={<KakaoLoginPage />} />

      {/* 앱 패키징용 페이지 */}
      <Route path="/app" element={<AppMainPage />} />
      <Route path="/app/compatible" element={<AppCompatiblePage />} />
      <Route path="/app/MyPage" element={<AppMyPage />} />
      <Route path="/app/login" element={<AppLoginPage />} />
      <Route path="/app/login/kakao/redirect" element={<AppKakaoLoginPage />} />
      <Route
        path="/app/login/naver/redirect"
        element={<AppNaverRedirectPage />}
      />
      {/* <Route path="/login/naver/redirect" element={<NaverLoginPage />} /> */}
      <Route path={"*"} element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
