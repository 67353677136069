import { BigFortune } from "../../utils/types";
import ShimmerEffect from "../Shimmer";

// 궁합페이지
export function BigFortuneTableV2({
  loading,
  bigFortuneProps,
  currentBigFortune,
  onClickBigFortune,
}: {
  loading: boolean;
  bigFortuneProps: BigFortune;
  currentBigFortune: number | null;
  onClickBigFortune: (value: number) => void;
}) {
  const bigFortune: BigFortune = {
    year: bigFortuneProps.year.slice(1),
    sky: bigFortuneProps.sky.slice(1),
    ground: bigFortuneProps.ground.slice(1),
  };
  return (
    <table
      className="w-full overflow-hidden"
      style={{
        fontWeight: 800,
        borderRadius: 6,
        cursor: "pointer",
        borderCollapse: "initial",
      }}
      cellSpacing="0"
    >
      <tbody className="grid w-full grid-cols-1">
        <tr className="bg-[#E7E7E7] w-full grid grid-cols-10 col-span-1">
          {bigFortune.year.map((item, index) => {
            const isCurrentBigFortune = 10 - index === currentBigFortune;
            return (
              <th
                key={`big-forturne-year-${index}`}
                className={`col-span-1 aspect-square border border-t-2 border-x-1 relative border-[#3C3C3C] text-xs sm:text-base ${
                  index === 0
                    ? " border-l-2"
                    : index === 9
                    ? " border-r-2"
                    : " border-x-1 "
                }`}
                style={{
                  borderTopLeftRadius: index === 0 ? 6 : 0,
                  borderTopRightRadius: index === 9 ? 6 : 0,
                }}
                onClick={() => {
                  onClickBigFortune(10 - index);
                }}
              >
                {!loading && (
                  <div className="flex items-center justify-center w-full h-full">
                    {item}
                  </div>
                )}
                {isCurrentBigFortune && (
                  <div
                    style={{ border: "2px solid red", borderBottom: "none" }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </th>
            );
          })}
        </tr>
        <tr className="grid w-full grid-cols-10 text-sm sm:text-xl">
          {bigFortune.sky.map((item, index) => {
            const isCurrentBigFortune = 10 - index === currentBigFortune;
            return (
              <td
                key={`big-forturne-sky-${index}`}
                className={`overflow-hidden col-span-1 border relative aspect-square border-[#3C3C3C] ${
                  index === 0
                    ? " border-l-2"
                    : index === 9
                    ? " border-r-2"
                    : " border-x-1 "
                }`}
                style={{
                  backgroundColor: !loading ? item.color : "none",
                  color: item.color === "#727272" ? "white" : "black",
                  fontFamily: "KPACJKSC",
                }}
                onClick={() => onClickBigFortune(10 - index)}
              >
                {!loading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    {item.chinese}
                  </div>
                ) : (
                  <ShimmerEffect />
                )}
                {isCurrentBigFortune && (
                  <div
                    style={{
                      border: "2px solid red",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </td>
            );
          })}
        </tr>
        <tr className="grid w-full grid-cols-10 text-sm sm:text-xl">
          {bigFortune.ground.map((item, index) => {
            const isCurrentBigFortune = 10 - index === currentBigFortune;
            return (
              <td
                key={`big-forturne-ground-${index}`}
                className={`overflow-hidden col-span-1 border border-b-2 relative aspect-square border-[#3C3C3C] ${
                  index === 0
                    ? " border-l-2"
                    : index === 9
                    ? " border-r-2"
                    : " border-x-1 "
                }`}
                style={{
                  borderBottomLeftRadius: index === 0 ? 6 : 0,
                  borderBottomRightRadius: index === 9 ? 6 : 0,
                  backgroundColor: !loading ? item.color : "none",
                  color: item.color === "#727272" ? "white" : "black",
                  fontFamily: "KPACJKSC",
                }}
                onClick={() => onClickBigFortune(10 - index)}
              >
                {!loading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    {item.chinese}
                  </div>
                ) : (
                  <ShimmerEffect />
                )}{" "}
                {isCurrentBigFortune && (
                  <div
                    style={{
                      border: "2px solid red",
                      borderTop: "none",
                    }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}
/** 사주보기 페이지 */
export default function BigFortuneTable({
  loading,
  bigFortuneProps,
  currentBigFortune,
  onClickBigFortune,
}: {
  loading: boolean;
  bigFortuneProps: BigFortune;
  currentBigFortune: number | null;
  onClickBigFortune: (value: number) => void;
}) {
  const bigFortune: BigFortune = {
    year: bigFortuneProps.year.slice(1),
    sky: bigFortuneProps.sky.slice(1),
    ground: bigFortuneProps.ground.slice(1),
  };
  return (
    <table
      className="w-full overflow-hidden  max-sm:text-[24px] lg:text-[24px]"
      style={{
        fontWeight: 800,
        borderRadius: 6,
        cursor: "pointer",
        borderCollapse: "initial",
      }}
      cellSpacing="0"
    >
      <tbody className="grid w-full grid-cols-1">
        <tr className="bg-[#E7E7E7] w-full grid grid-cols-10 col-span-1">
          {bigFortune.year.map((item, index) => {
            const isCurrentBigFortune = 10 - index === currentBigFortune;
            return (
              <th
                key={`big-forturne-year-${index}`}
                className={`col-span-1 aspect-square border border-t-2 border-x-1 relative border-[#3C3C3C] text-base ${
                  index === 0
                    ? " border-l-2"
                    : index === 9
                    ? " border-r-2"
                    : " border-x-1 "
                }`}
                style={{
                  borderTopLeftRadius: index === 0 ? 6 : 0,
                  borderTopRightRadius: index === 9 ? 6 : 0,
                }}
                onClick={() => {
                  onClickBigFortune(10 - index);
                }}
              >
                {!loading && (
                  <div className="flex items-center justify-center w-full h-full">
                    {item}
                  </div>
                )}
                {isCurrentBigFortune && (
                  <div
                    style={{ border: "2px solid red", borderBottom: "none" }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </th>
            );
          })}
        </tr>
        <tr className="grid w-full grid-cols-10">
          {bigFortune.sky.map((item, index) => {
            const isCurrentBigFortune = 10 - index === currentBigFortune;
            return (
              <td
                key={`big-forturne-sky-${index}`}
                className={`overflow-hidden col-span-1 border relative aspect-square border-[#3C3C3C] ${
                  index === 0
                    ? " border-l-2"
                    : index === 9
                    ? " border-r-2"
                    : " border-x-1 "
                }`}
                style={{
                  backgroundColor: !loading ? item.color : "none",
                  color: item.color === "#727272" ? "white" : "black",
                  fontFamily: "KPACJKSC",
                }}
                onClick={() => onClickBigFortune(10 - index)}
              >
                {!loading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    {item.chinese}
                  </div>
                ) : (
                  <ShimmerEffect />
                )}
                {isCurrentBigFortune && (
                  <div
                    style={{
                      border: "2px solid red",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </td>
            );
          })}
        </tr>
        <tr className="grid w-full grid-cols-10">
          {bigFortune.ground.map((item, index) => {
            const isCurrentBigFortune = 10 - index === currentBigFortune;
            return (
              <td
                key={`big-forturne-ground-${index}`}
                className={`overflow-hidden col-span-1 border border-b-2 relative aspect-square print-text-xl border-[#3C3C3C] ${
                  index === 0
                    ? " border-l-2"
                    : index === 9
                    ? " border-r-2"
                    : " border-x-1 "
                }`}
                style={{
                  borderBottomLeftRadius: index === 0 ? 6 : 0,
                  borderBottomRightRadius: index === 9 ? 6 : 0,
                  backgroundColor: !loading ? item.color : "none",
                  color: item.color === "#727272" ? "white" : "black",
                  fontFamily: "KPACJKSC",
                }}
                onClick={() => onClickBigFortune(10 - index)}
              >
                {!loading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    {item.chinese}
                  </div>
                ) : (
                  <ShimmerEffect />
                )}{" "}
                {isCurrentBigFortune && (
                  <div
                    style={{
                      border: "2px solid red",
                      borderTop: "none",
                    }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}
