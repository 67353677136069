import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authAxios } from "../framework/api";
import { loginTokenKey, setItem } from "../utils/localStorage";
import { AuthStateContext } from "../contexts/AuthStateContext";

const NaverLogin = () => {
  //   const [size, setSize] = useState(true);
  const { handleChangeAuthState } = useContext(AuthStateContext);

  const navigate = useNavigate();
  // useEffect(() => {
  //   // 네이버 로그인 SDK 스크립트가 이미 페이지에 존재하는지 확인
  //   if (!document.getElementById("naver-login-sdk")) {
  //     const naverScript = document.createElement("script");
  //     naverScript.id = "naver-login-sdk";
  //     naverScript.src =
  //       "https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js";
  //     naverScript.type = "text/javascript";
  //     document.head.appendChild(naverScript);

  //     naverScript.onload = () => {
  //       if (!window.naver) {
  //         console.error("Naver SDK 로드 실패");
  //         window.location.reload();
  //         return;
  //       }

  //       // 여기서는 babel-polyfill이 중복 로드되는 것을 방지하기 위해
  //       // window 객체에 naver 속성이 존재하는지만 확인하고 있습니다.
  //       // 필요하다면 여기에 polyfill 로드 여부도 체크할 수 있습니다.
  //       const redirect_uri_naver = `${process.env.REACT_APP_ROOT_URL}/login`;
  //       const naverLogin = new window.naver.LoginWithNaverId({
  //         clientId: process.env.REACT_APP_NAVER_KEY,
  //         callbackUrl: redirect_uri_naver,
  //         callbackHandle: true,
  //         isPopup: false,
  //         loginButton: { color: "green", type: 3, height: 30 },
  //       });

  //       naverLogin.init();
  //       naverLogin.logout();
  //       console.log("네이버 로그아웃");
  //       naverLogin.getLoginStatus((status) => {
  //         if (status) {
  //           console.log("status", status);
  //           console.log("ddd", naverLogin.user);
  //           authAxios
  //             .post(`/mobile/auth/social`, {
  //               type: "naver",
  //               socialId: naverLogin.user.id,
  //               email: naverLogin.user.email,
  //             })
  //             .then((socialLogin) => {
  //               console.log("naverLogin");
  //               setItem(loginTokenKey, socialLogin.data);
  //               handleChangeAuthState(true);
  //               navigate("/", { replace: true });
  //             })
  //             .catch((error) => {
  //               console.log(error);
  //               // naverLogin.logout();
  //             });
  //           // setSize(false);
  //         } else {
  //           console.log("실패 staus", status);
  //           console.log("Naver 로그인 상태 확인 실패");
  //           // setSize(true);
  //         }
  //       });
  //     };
  //   }
  // }, []);

  useEffect(() => {
    const loadNaverScript = () => {
      if (!document.getElementById("naver-login-sdk")) {
        const naverScript = document.createElement("script");
        naverScript.id = "naver-login-sdk";
        naverScript.src =
          "https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js";
        naverScript.type = "text/javascript";
        document.head.appendChild(naverScript);

        naverScript.onload = () => {
          console.log("Naver SDK 로드 완료");
          if (!window.naver) {
            console.error("Naver SDK 로드 실패");
            window.location.reload();
            return;
          }

          const naverLogin = new window.naver.LoginWithNaverId({
            clientId: process.env.REACT_APP_NAVER_KEY,
            callbackUrl: `${process.env.REACT_APP_ROOT_URL}/login`,
            isPopup: false,
            loginButton: { color: "green", type: 3, height: 30 },
          });

          naverLogin.init();
          console.log("Naver 로그인 초기화 완료");
          naverLogin.getLoginStatus((status) => {
            if (status) {
              console.log("Naver 로그인 상태 확인 성공");
              console.log("Naver 사용자 정보:", naverLogin.user);
              authAxios
                .post(`/mobile/auth/social`, {
                  type: "naver",
                  socialId: naverLogin.user.id,
                  email: naverLogin.user.email,
                })
                .then((socialLogin) => {
                  console.log("naverLogin");
                  setItem(loginTokenKey, socialLogin.data);
                  handleChangeAuthState(true);
                  navigate("/", { replace: true });
                })
                .catch((error) => {
                  console.error("Naver 로그인 처리 실패", error);
                  // 필요한 경우 네이버 로그아웃 처리 추가
                });
            } else {
              console.log("Naver 로그인 상태 확인 실패");
            }
          });
        };

        naverScript.onerror = () => {
          console.error("Naver SDK 로드 오류");
          window.location.reload();
        };
      } else {
        window.location.reload();
      }
    };

    loadNaverScript();
  }, []);

  return (
    <div
      style={{
        width: "180px",
        overflow: "hidden",
        margin: "auto",
      }}
      id="naverIdLogin"
    ></div>
  );
};

export default NaverLogin;
