import { BigFortune, HundredList } from "../../utils/types";
interface HundredTableProps {
  bigFortuneProps: BigFortune;
  hundredList: HundredList;
  currentSmallFortune: number | null;
  onClickHundredTable: (bigNum: number, smallNum: number) => void;
}

function chunkHundredList(
  hundredList: HundredList,
  bigFortuneFirstYear: number
): HundredList[] {
  const chunkedArray2: HundredList[] = [];
  const { year, sky, ground, age } = hundredList;

  const findSeperateIndex = age.findIndex(
    (item) => item === bigFortuneFirstYear
  );
  //   //console.log("findSeperateIndex", findSeperateIndex);

  const prevHundredList: HundredList = {
    year: year.slice(0, findSeperateIndex).reverse(),
    sky: sky.slice(0, findSeperateIndex).reverse(),
    ground: ground.slice(0, findSeperateIndex).reverse(),
    age: age.slice(0, findSeperateIndex).reverse(),
    // lessAge: age.slice(0, findSeperateIndex).reverse(),
  };
  const nextHundredList: HundredList = {
    year: year.slice(findSeperateIndex),
    sky: sky.slice(findSeperateIndex),
    ground: ground.slice(findSeperateIndex),
    age: age.slice(findSeperateIndex),
    // lessAge: age.slice(findSeperateIndex),
  };

  chunkedArray2.push(prevHundredList);

  let nextIndex = 0;
  while (nextIndex < nextHundredList.age.length) {
    const chunk = {
      year: fillWithUndefined(
        nextHundredList.year.slice(nextIndex, nextIndex + 10)
      ).reverse(),
      sky: fillWithUndefined(
        nextHundredList.sky.slice(nextIndex, nextIndex + 10)
      ).reverse(),
      ground: fillWithUndefined(
        nextHundredList.ground.slice(nextIndex, nextIndex + 10)
      ).reverse(),
      age: fillWithUndefined(
        nextHundredList.age.slice(nextIndex, nextIndex + 10)
      ).reverse(),
      // lessAge: fillWithUndefined(
      //   nextHundredList.lessAge.slice(nextIndex, nextIndex + 10)
      // ).reverse(),
    };
    chunkedArray2.push(chunk);

    nextIndex += 10;
  }

  return chunkedArray2;
}
function fillWithUndefined(arr: any[]) {
  while (arr.length < 10) {
    arr.push(undefined);
  }
  return arr;
}
export default function HundredTable(props: HundredTableProps) {
  const {
    bigFortuneProps,
    hundredList,
    currentSmallFortune,
    onClickHundredTable,
  } = props;
  // 셀을 클릭했을 때 처리하는 함수
  const handleCellClick = (smallNum: number, bigNum: number) => {
    if (smallNum !== undefined) {
      onClickHundredTable(bigFortuneShowLength - bigNum, smallNum);
    }
  };

  const bigFortuneFirstYear = bigFortuneProps.year.at(-2);
  const bigFortuneLength = bigFortuneProps.year.length - 1;
  const bigFortuneShowLength = bigFortuneProps.year.length;

  const chunkedHundredList = chunkHundredList(
    hundredList,
    bigFortuneFirstYear as number
  );

  return (
    <table
      className="w-full overflow-hidden text-xs"
      style={{
        fontWeight: 800,
        borderRadius: 6,
        border: "1px solid black",
        borderTop: "0px",
        borderBottom: "2px solid black",
        cursor: "pointer",
        borderCollapse: "initial",
      }}
      cellSpacing="0"
    >
      <tbody>
        {chunkedHundredList.map((chunk, chunkIndex) => (
          <tr
            key={`chunkedHundredList-${chunkIndex}`}
            className="grid w-full grid-cols-11"
          >
            {Array.from({ length: 10 }).map((_, cellIndex) => {
              const year = chunk.year[cellIndex];
              const sky = chunk.sky[cellIndex];
              const ground = chunk.ground[cellIndex];
              const age = chunk.age[cellIndex];

              // 현재 선택된 연도인지 확인
              const isCurrentSmallFortune = year === currentSmallFortune;

              // 조건부 스타일
              const tdStyle = {
                borderTop: "2px solid black",
                borderLeft: "1px solid black",
                overflow: "hidden",
                // height: "40px",
                // margin: "0px",
                // padding: "0px",
                padding: "0px",
                margin: "0px",
              };
              if (age < 0) {
                return (
                  <td
                    key={cellIndex}
                    style={{ ...tdStyle, position: "relative" }}
                  />
                );
              }
              return (
                <td
                  key={cellIndex}
                  style={{ ...tdStyle, position: "relative" }}
                  onClick={() =>
                    year !== undefined &&
                    handleCellClick(year, bigFortuneLength - chunkIndex)
                  }
                >
                  {/* 연도 표시 */}
                  <div className="flex flex-col items-center w-full h-full ">
                    <p className="leading-none py-[2px]">
                      {year !== undefined ? year : ""}
                    </p>
                    {/* 나이 만 나이 표시 */}
                    {age !== undefined ? (
                      <p className="py-[2px] leading-none text-center w-full border-black border-y bg-[#E7E7E7]">
                        {age}
                      </p>
                    ) : (
                      ""
                    )}

                    {/* sky의 한자 배경색은 skyColor, radius는 원형, 글씨 색깔은 검은색으로 */}
                    <div className="flex flex-col items-center justify-center w-full h-full text-[13px]">
                      {sky ? (
                        <span
                          className="flex items-center justify-center w-full py-[1.5px]"
                          style={{
                            fontFamily: "KPACJKSC",
                            backgroundColor: sky.color,
                            color: sky.color === "#727272" ? "white" : "black",
                          }}
                        >
                          {sky.chinese}
                        </span>
                      ) : (
                        ""
                      )}
                      {/* ground의 한자, groundColor, radius는 원형, 글씨 색깔은 검은색으로*/}
                      {ground ? (
                        <span
                          className="flex items-center justify-center w-full py-[1.5px]"
                          style={{
                            fontFamily: "KPACJKSC",
                            backgroundColor: ground.color,
                            color:
                              ground.color === "#727272" ? "white" : "black",
                          }}
                        >
                          {ground.chinese}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {isCurrentSmallFortune && (
                    <div
                      style={{ border: "2px solid red" }}
                      className="z-[10000] absolute top-0 left-0 w-full h-full "
                    />
                  )}
                </td>
              );
            })}
            <td
              style={{
                borderTop: "2px solid black",
                // borderBottom: "1px solid black",
                borderLeft: "3px solid black",
                borderRight: "0.5px solid black",
                // height: "40px",
                // margin: "0px",
                // padding: "0px",
                padding: "0px",
                margin: "0px",
              }}
            >
              <div className="flex flex-col items-center w-full h-full">
                <p className="flex justify-center w-full border-b border-black bg-[#E7E7E7] h-full items-center">
                  {bigFortuneProps.year[bigFortuneLength - chunkIndex]}
                </p>
                {/* sky의 한자 배경색은 skyColor, radius는 원형, 글씨 색깔은 검은색으로 */}
                <div className="flex flex-col items-center w-full h-full text-[13px]">
                  {bigFortuneProps.sky[bigFortuneLength - chunkIndex] ? (
                    <span
                      className="flex items-center justify-center w-full py-[1.5px] "
                      style={{
                        backgroundColor:
                          bigFortuneProps.sky[bigFortuneLength - chunkIndex]
                            .color,

                        color:
                          bigFortuneProps.sky[bigFortuneLength - chunkIndex]
                            .color === "#727272"
                            ? "white"
                            : "black",

                        fontFamily: "KPACJKSC",
                      }}
                    >
                      {
                        bigFortuneProps.sky[bigFortuneLength - chunkIndex]
                          .chinese
                      }
                    </span>
                  ) : (
                    ""
                  )}
                  {/* ground의 한자, groundColor, radius는 원형, 글씨 색깔은 검은색으로*/}
                  {bigFortuneProps.ground[bigFortuneLength - chunkIndex] ? (
                    <span
                      className="flex items-center justify-center w-full px-1 py-[1.5px]"
                      style={{
                        backgroundColor:
                          bigFortuneProps.ground[bigFortuneLength - chunkIndex]
                            .color,

                        color:
                          bigFortuneProps.ground[bigFortuneLength - chunkIndex]
                            .color === "#727272"
                            ? "white"
                            : "black",
                        fontFamily: "KPACJKSC",
                      }}
                    >
                      {
                        bigFortuneProps.ground[bigFortuneLength - chunkIndex]
                          .chinese
                      }
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
