import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import KAKAOLOGINIGM from "../../assets/images/kakao_login_medium_narrow.png";
import GOOGLELOGINGM from "../../assets/images/google_login_medium_narrow.png";

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { AuthStateContext } from "../../contexts/AuthStateContext";
import { authAxios } from "../../framework/api";
import { loginTokenKey, setItem } from "../../utils/localStorage";
import NaverLogin from "../../components/AppNaverLogin2";

const KAKAO_REST_API_KEY = process.env.REACT_APP_KAKAO_KEY;
const redirect_uri_kakao = `${process.env.REACT_APP_ROOT_URL}/app/login/kakao/redirect`;

export default function AppLoginPage() {
  const navigate = useNavigate();
  const { authState, handleChangeAuthState } = useContext(AuthStateContext);

  const onClickKakaoLogin = () => {
    console.log(redirect_uri_kakao);
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_REST_API_KEY}&redirect_uri=${redirect_uri_kakao}&prompt=select_account`;
    window.location.href = kakaoURL;
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: "application/json",
            },
          }
        );
        if (res.status === 200) {
          const socialLogin = await authAxios.post(`/mobile/auth/social`, {
            type: "google",
            socialId: res.data.id,
            email: res.data.email,
          });
          //console.log(socialLogin);
          console.log("googleLogin");

          if (socialLogin.status === 201 || socialLogin.status === 200) {
            setItem(loginTokenKey, socialLogin.data);
            handleChangeAuthState(true);
            navigate("/app", { replace: true });
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
        }
      }
    },

    onError: () => {
      //console.log("login fail");
    },
  });

  useEffect(() => {
    if (authState) {
      navigate("/app", { replace: true });
    } else {
    }
  }, [authState, navigate]);
  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-2">
      <div className="flex flex-col items-center justify-center p-2">
        <header className="mb-10 text-2xl font-extrabold">이규호 만세력</header>
        <div className="flex flex-col items-center justify-center w-full gap-3 ">
          <button onClick={onClickKakaoLogin} className=" w-[180px]">
            <img src={KAKAOLOGINIGM} alt="kakao_login_middle" />
          </button>

          <NaverLogin />
          <button
            onClick={() => {
              console.log("googleLogin");
              // login();
            }}
            className=""
          >
            <img
              className="w-[180px]"
              src={GOOGLELOGINGM}
              loading="lazy"
              alt="google logo"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
