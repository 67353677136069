import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthStateContext } from "../contexts/AuthStateContext";
import KAKAOLOGINIGM from "../assets/images/kakao_login_medium_narrow.png";
import GOOGLELOGINGM from "../assets/images/google_login_medium_narrow.png";
import NaverLogin from "../components/NaverLogin";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { authAxios } from "../framework/api";
import { loginTokenKey, setItem } from "../utils/localStorage";

const KAKAO_REST_API_KEY = process.env.REACT_APP_KAKAO_KEY;
const redirect_uri_kakao = `${process.env.REACT_APP_ROOT_URL}/login/kakao/redirect`;

export default function LoginPage() {
  const navigate = useNavigate();

  const { authState, handleChangeAuthState } = useContext(AuthStateContext);
  // //console.log(redirect_uri_kakao);

  const onClickKakaoLogin = () => {
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_REST_API_KEY}&redirect_uri=${redirect_uri_kakao}&prompt=select_account`;
    window.location.href = kakaoURL;
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: "application/json",
            },
          }
        );
        if (res.status === 200) {
          //console.log(res.data.email);
          //console.log(res.data.id);
          const socialLogin = await authAxios.post(`/mobile/auth/social`, {
            type: "google",
            socialId: res.data.id,
            email: res.data.email,
          });
          //console.log(socialLogin);

          if (socialLogin.status === 201 || socialLogin.status === 200) {
            setItem(loginTokenKey, socialLogin.data);
            handleChangeAuthState(true);
            navigate("/", { replace: true });
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
        }
      }
    },

    onError: () => {
      //console.log("login fail");
    },
  });

  useEffect(() => {
    if (authState) {
      navigate("/", { replace: true });
    }
  }, [authState]);
  // 페이지 들어와서 한번만 갱신하게 하고 싶음.

  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-2">
      <div className="flex flex-col items-center justify-center p-2 border rounded-md border-black/25">
        <header className="mb-10 text-2xl font-extrabold">이규호 만세력</header>
        <div className=" w-[300px] flex flex-col items-center justify-center gap-3">
          <button onClick={onClickKakaoLogin} className=" w-[180px]">
            <img src={KAKAOLOGINIGM} alt="kakao_login_middle" />
          </button>

          <NaverLogin />

          <button
            onClick={() => {
              console.log("googleLogin");
              login();
            }}
            className=""
          >
            <img
              className="w-[180px]"
              src={GOOGLELOGINGM}
              loading="lazy"
              alt="google logo"
            />
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center ">
        <div className="font-semibold text-black">
          안전한 로그인을 위한 유의사항
        </div>
        <ul
          className="p-2 border-t border-slate-300"
          style={{ listStyle: "inside" }}
        >
          <li>
            공용 컴퓨터에서의 사용: 이 컴퓨터를 다른 사람과 공유한다면, 작업이
            끝난 후 반드시 '로그아웃'을 해주세요. 그렇지 않으면, 개인 정보가
            다음 사용자에게 노출될 수 있습니다.
          </li>
          <li>
            개인 컴퓨터에서의 사용: 개인 컴퓨터에서도 브라우저나 탭을 닫기 전에
            '로그아웃'을 하는 습관을 들이는 것이 좋습니다.
          </li>
          <li>
            컴퓨터 종료 전 확인: 컴퓨터를 종료하거나 재시작하기 전에 로그아웃
            했는지 확인해주세요.
          </li>
        </ul>
      </div>
    </div>
  );
}

//!document.getElementById("naver-login-sdk")이면 페이지 리로드
