function urlNav({
  url,
  gender,
  birthType,
  yearMonthDay,
  hourMin,
  unknownTime,
  currentBigFortune = null,
  currentSmallFortune = null,
  currentMonthFortune = null,
}: {
  url: string;
  gender: string;
  birthType: string;
  yearMonthDay: string;
  hourMin: string;
  unknownTime: boolean;
  currentBigFortune?: null | number;
  currentSmallFortune?: null | number;
  currentMonthFortune?: null | number;
}) {
  let _url = url;
  _url += `?gender=${gender}`;
  _url += `&birthdayType=${birthType}`;
  _url += `&birthday=${yearMonthDay}`;
  _url += `&time=${hourMin}`;
  _url += `&unknownTime=${unknownTime}`;
  _url += currentBigFortune === null ? "" : `&bigNum=${currentBigFortune}`;
  _url +=
    currentSmallFortune === null ? "" : `&smallNum=${currentSmallFortune}`;
  _url +=
    currentMonthFortune === null ? "" : `&monthNum=${currentMonthFortune}`;

  return _url;
}

export default urlNav;
