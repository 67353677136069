import { formatBirthday, getYMDString } from "../utils/timeConvert";
import ShimmerEffect from "./Shimmer";

interface IClientProps {
  loading: boolean;
  nickName: string | null;
  genderType: string;
  birthday: string | null;
  birthDayType: string;
  seasonName: string | undefined;
  summmerTime: number | undefined;
  unknownTime: boolean;
  lunaDate: string;
  solarDate: string;
  age: number;
}
/** 사주보기 페이지 */
export default function ClientInfo(props: IClientProps) {
  const {
    nickName, //A
    genderType,
    birthDayType,
    seasonName, //B
    summmerTime, //C
    lunaDate,
    solarDate,
    age,
    loading,
  } = props;

  return (
    <div className="w-full min-h-[56px] grid grid-cols-2 gap-2 font-bold mb-2">
      <div className="overflow-hidden relative rounded-xl bg-[#E9E9E9] col-span-1">
        {!loading ? (
          <div className="flex flex-col items-center justify-center w-full h-full ">
            <span
              className={
                nickName
                  ? nickName.length > 9
                    ? "text-base max-sm:text-xs sm:text-[11px] lg:text-[13px]"
                    : nickName.length > 5
                    ? "text-base max-sm:text-xs sm:text-xs lg:text-sm"
                    : "max-sm:text-2xl sm:text-lg md:text-xl"
                  : "max-sm:text-2xl sm:text-xl md:text-xl"
              }
            >
              <span>{nickName}</span>{" "}
              {genderType === "MALE" ? (
                <span className="font-normal text-blue-500">男</span>
              ) : (
                <span className="font-normal text-red-500">女</span>
              )}
              {age}세
            </span>

            <span>
              {seasonName && (
                <span className="pl-2 text-blue-600">{seasonName}</span>
              )}
              {summmerTime === 1 && <span>(썸머타임)</span>}
            </span>
          </div>
        ) : (
          <ShimmerEffect />
        )}
      </div>

      <div className="overflow-hidden relative rounded-xl col-span-1 bg-[#E9E9E9] flex flex-col items-center justify-center">
        {!loading ? (
          <>
            <span>
              {formatBirthday(solarDate)}
              <span className="font-normal">{" 陽"}</span>
            </span>
            <span>
              {formatBirthday(lunaDate)}
              <span className="font-normal">
                {birthDayType === "LEAP" ? (
                  <span style={{ color: "red" }}>{" 閏"}</span>
                ) : (
                  " 陰"
                )}
              </span>
            </span>
          </>
        ) : (
          <ShimmerEffect />
        )}
      </div>
    </div>
  );
}
/** 궁합페이지 */
export function ClientInfoV2(props: IClientProps) {
  const {
    nickName, //A
    genderType,
    birthDayType,
    seasonName, //B
    summmerTime, //C
    lunaDate,
    solarDate,
    age,
    loading,
  } = props;

  return (
    <div className="w-full min-h-[56px] grid grid-cols-2 gap-2 font-bold mb-2">
      <div className="overflow-hidden relative rounded-xl bg-[#E9E9E9] col-span-1">
        {!loading ? (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <span
              className={
                nickName
                  ? nickName.length > 9
                    ? "text-[1.6vw] sm:text-[11px]"
                    : nickName.length > 8
                    ? "text-[1.6vw] sm:text-xs"
                    : nickName.length > 7
                    ? "text-[1.8vw] sm:text-xs"
                    : nickName.length > 6
                    ? "text-[2vw] sm:text-sm"
                    : nickName.length > 5
                    ? "text-[2.3vw] sm:text-base"
                    : nickName.length > 4
                    ? "text-[2.5vw] sm:text-lg"
                    : nickName.length > 3
                    ? "text-[3vw] sm:text-xl"
                    : "text-[3.2vw] sm:text-xl"
                  : "max-sm:text-2xl sm:text-xl md:text-xl"
              }
            >
              <span>{nickName}</span>{" "}
              {genderType === "MALE" ? (
                <span className="font-normal text-blue-500">男</span>
              ) : (
                <span className="font-normal text-red-500">女</span>
              )}
              {age}세
            </span>

            <span className="text-[10px] sm:text-base">
              {seasonName && (
                <span className="pl-2 text-blue-600">{seasonName}</span>
              )}
              {summmerTime === 1 && <span>(썸머타임)</span>}
            </span>
          </div>
        ) : (
          <ShimmerEffect />
        )}
      </div>

      <div className="overflow-hidden relative rounded-xl col-span-1 bg-[#E9E9E9] flex flex-col items-center justify-center text-xs sm:text-base">
        {!loading ? (
          <>
            <span>
              {formatBirthday(solarDate)}
              <span className="font-normal">{" 陽"}</span>
            </span>
            <span>
              {formatBirthday(lunaDate)}
              <span className="font-normal">
                {" "}
                {birthDayType === "LEAP" ? (
                  <span style={{ color: "red" }}>{" 閏"}</span>
                ) : (
                  " 陰"
                )}
              </span>
            </span>
          </>
        ) : (
          <ShimmerEffect />
        )}
      </div>
    </div>
  );
}
