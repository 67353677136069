import "./ShimmerEffect.css"; // 위에서 만든 CSS 파일 임포트

// ShimmerEffect 컴포넌트
const ShimmerEffect = () => {
  return (
    <div className="absolute w-full h-full overflow-hidden bg-gray-50/80 shimmer-effect" />
  );
};

export default ShimmerEffect;
