import { parse, format } from "date-fns";
import { useState, FormEvent } from "react";
import { GENDERTYPE } from "../contexts/SaveDataContext";
import { timeOptions } from "../utils/data";
import {
  convertDateString,
  getYMDString,
  convertTimeString,
  getHMString,
  formatTimeWithColon,
} from "../utils/timeConvert";
import { ClientInfo } from "../utils/types";
import CustomInput from "./Input";
import { CustomSelectChoose } from "./Selector";
import CustomButton from "./Button";

interface Props {
  updateClient?: ClientInfo;
  onClose: Function;
  handleUpdateClent: (data: ClientInfo) => void;
}
export default function InfoUPdateDialog(props: Props) {
  const [name, setName] = useState(props.updateClient?.nickname ?? "");
  const [yearMonthDay, setYearMonthDay] = useState(
    convertDateString(props.updateClient?.birthday ?? "") ??
      getYMDString(new Date())
  );
  const [hourMin, setHourMin] = useState(
    convertTimeString(props.updateClient?.time ?? "") ?? getHMString(new Date())
  );
  const [birthType, setBirthType] = useState<string>(
    props.updateClient?.birthdayType ?? ""
  );
  const [gender, setGender] = useState<string>(
    props.updateClient?.gender ?? "FEMALE"
  );

  const [inputDisabled, setInputDisabled] = useState(false);
  const [currentTimeOption, setCurrentTimeOption] = useState("default");

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;

    if (value === "default") {
      setInputDisabled(false);
      setCurrentTimeOption(value);
    } else {
      setInputDisabled(true);
      setCurrentTimeOption(value);
      setHourMin(value);
    }
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const parsedDate = parse(yearMonthDay, "yyyyMMdd", new Date());
    const formattedDate = format(parsedDate, "yyyy-MM-dd");
    const formattedTime =
      hourMin === "????" ? "????" : formatTimeWithColon(hourMin);
    // const time = format(formattedTime, "HH:mm");

    props.handleUpdateClent({
      ...props.updateClient!,
      nickname: name,
      gender: gender,
      birthday: formattedDate,
      birthdayType: birthType,
      time: formattedTime,
      unknownTime: hourMin === "????" ? true : false,
    });
  };
  return (
    <div className=" bg-black/50 fixed right-0 left-0 top-0 bottom-0 z-[999] flex justify-center items-center">
      <div className=" sm:w-full max-w-[360px] bg-white rounded-md border border-white grid grid-cols-1 drop-shadow-lg  p-3 gap-3">
        <form
          className="grid grid-cols-4 gap-3"
          onSubmit={(e: FormEvent<HTMLFormElement>) => handleSubmit(e)}
        >
          <CustomInput
            label="성명"
            type="text"
            value={name}
            onChange={(e) => setName(e.currentTarget.value.slice(0, 10))}
            className={"col-span-4"}
            placeholder="이름을 입력해 주세요"
          />
          <CustomInput
            maxLength={8}
            type={"input"}
            name={"yearMonthDay"}
            label={"년월일"}
            placeholder="20230704"
            value={yearMonthDay}
            onChange={(e) => {
              const newValue = e.currentTarget.value.replace(/[^0-9]/g, "");
              setYearMonthDay(newValue);
            }}
            className={" col-span-4"}
          />
          <CustomInput
            maxLength={4}
            type={"text"}
            name={"hourMin"}
            label={"시 입력"}
            disabled={inputDisabled}
            value={hourMin}
            onChange={(e) => {
              const newValue = e.currentTarget.value.replace(/[^0-9]/g, "");
              setHourMin(newValue);
            }}
            placeholder="1522"
            className={" col-span-2"}
          />
          <CustomSelectChoose
            title="시 선택"
            options={timeOptions}
            value={currentTimeOption}
            onChange={handleChange}
            className={" col-span-2"}
          />

          <CustomSelectChoose
            title="양,음,윤"
            options={[
              { key: "양력", value: "SOLAR" },
              { key: "음력", value: "LUNAR" },
              { key: "윤달", value: "LEAP" },
            ]}
            value={birthType}
            onChange={(e) => {
              setBirthType(e.currentTarget.value);
            }}
            className=" col-span-2 h-[54px] sm:h-[4vw] sm:max-h-[40px]"
          />
          <CustomSelectChoose
            title="성별"
            options={[
              { key: "남", value: "MALE" },
              { key: "여", value: "FEMALE" },
            ]}
            value={gender}
            onChange={(e) => {
              setGender(e.currentTarget.value as GENDERTYPE);
            }}
            className=" col-span-2 h-[54px] sm:h-[4vw] sm:max-h-[40px]"
          />
          <CustomButton
            className="col-span-2 h-[54px]"
            onClick={() => props.onClose()}
            text="취소"
            primaryType={false}
          />
          <CustomButton
            disabled={
              hourMin.length !== 4 ||
              yearMonthDay.length !== 8 ||
              name.trim().length === 0
            }
            className="col-span-2 h-[54px]"
            type="submit"
            text="수정"
            primaryType={true}
          />
        </form>
      </div>
    </div>
  );
}
