import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthStateContext } from "../contexts/AuthStateContext";
import { useReactToPrint } from "react-to-print";
import { MenuClose } from "../assets";
import { isMobile } from "react-device-detect";
import "./printStyle.css";

interface MenuProps {
  printRef: React.RefObject<HTMLDivElement>;
}
const Menu: React.FC<MenuProps> = (props) => {
  const { printRef } = props;
  const { authState, handleChangeAuthState } = useContext(AuthStateContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const onClickMenu = (): void => {
    setOpen((prev) => !prev);
  };

  const onClickLogout = (): void => {
    // navigate("/");
    handleChangeAuthState(false);
    localStorage.clear();
    setOpen(false);
  };

  const onClickLogin = (): void => {
    navigate("/login");
  };

  const onClickCompatible = (): void => {
    navigate("/compatible");
  };
  const onClickMain = (): void => {
    navigate("/");
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => {
      const PrintElem = document.createElement("div");
      if (printRef.current) {
        // Check that printRef.current is not null
        const printArea = printRef.current.cloneNode(true) as HTMLDivElement;
        PrintElem.style.padding = "20px";
        PrintElem.appendChild(printArea);
      }
      return PrintElem;
    },
    onBeforeGetContent: () => {
      if (printRef.current) {
        printRef.current.classList.add("print-version");
      }
    },
    // onBeforePrint: () => {
    //   if (printRef.current) {
    //     printRef.current.classList.add("print-version");
    //   }
    // },
    onAfterPrint: () => {
      if (printRef.current) {
        printRef.current.classList.remove("print-version");
      }
    },

    copyStyles: true,
    documentTitle: `만세력 사주보기`,
    // onAfterPrint: () => //console.log("Print job completed."),
    onPrintError: (error) => alert("There is an error when printing: " + error),
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-6 h-6" ref={menuRef} style={{ zIndex: 100000 }}>
      <button onClick={onClickMenu}>
        <svg width="24" height="18" viewBox="0 0 24 18">
          <rect width="24" height="2"></rect>
          <rect y="8" width="24" height="2"></rect>
          <rect y="16" width="24" height="2"></rect>
        </svg>
      </button>
      {open && (
        <div className="absolute top-0 right-0 bg-white w-[136px] h-[186px] text-black z-50 flex flex-col items-start justify-end p-4 drop-shadow-md gap-3">
          <MenuClose
            className="absolute top-4 right-4"
            onClick={() => setOpen(false)}
          />
          <button
            onClick={onClickMain}
            className="text-xl font-semibold hover:bg-slate-300"
          >
            메인화면
          </button>
          <button
            onClick={onClickCompatible}
            className="text-xl font-semibold hover:bg-slate-300"
          >
            궁합보기
          </button>
          {authState && (
            <button
              onClick={onClickLogout}
              className="text-xl font-semibold hover:bg-slate-300"
            >
              로그아웃
            </button>
          )}
          {!authState && (
            <button
              onClick={onClickLogin}
              className="text-xl font-semibold hover:bg-slate-300"
            >
              로그인
            </button>
          )}
          {!isMobile && (
            <button
              onClick={handlePrint}
              className="text-xl font-semibold hover:bg-slate-300"
            >
              인쇄하기
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Menu;
