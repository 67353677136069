import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../components/Button";
import CustomInput from "../components/Input";
import CustomSelect, { CustomSelectChoose } from "../components/Selector";
import { AuthStateContext } from "../contexts/AuthStateContext";
import { defaultSet, timeOptionsMain } from "../utils/data";
import { HundredList, Root } from "../utils/types";
import {
  convertDateString,
  convertTimeString,
  getCurrentMonth,
  getCurrentYear,
  getHMString,
  getYMDString,
} from "../utils/timeConvert";
import { getSaju } from "../framework/api";
import { Calendar } from "../components/Calender";
import { SajuTableV2 } from "../components/Table/SajuTable";
import BigFortuneTable from "../components/Table/BigFortuneTable";
import { SmallFortuneTableV3 } from "../components/Table/SmallFortuneTable";
import MonthFortuneTable from "../components/Table/MonthFortuneTable";
import Menu from "../components/Menu";
import SummerTimeTable from "../assets/images/SummerTimeTable.png";
import ClientInfo from "../components/ClientInfo";
import HundredTable from "../components/Table/HundredTable";
import { isMobile } from "react-device-detect";
import ShimmerEffect from "../components/Shimmer";
import { createSmallFortuneTableArray } from "../utils/createSmallFortuneTableArray";
import { GENDERTYPE, SaveDataContext } from "../contexts/SaveDataContext";
import urlNav from "../utils/urlNav";

export default function MainPage() {
  const { defaultUser, saveDefaultUser, loadDefaultUser, reset } =
    useContext(SaveDataContext);
  const nickName = defaultUser.nickName;
  const genderType = defaultUser.genderType;
  const birthDayType = defaultUser.birthDayType;
  const time = defaultUser.time;
  const birthday = defaultUser.birthday;
  const unknownTime = defaultUser.unknownTime;
  console.log("defaultUser::", defaultUser);

  const navigate = useNavigate();
  const printRef = useRef<HTMLDivElement>(null);
  const { authState } = useContext(AuthStateContext);
  const [result, setResult] = useState<Root>(defaultSet);
  const [showCalendar, setShowCalendar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hundredList, setHundredList] = useState<HundredList[]>(
    createSmallFortuneTableArray(defaultSet.hundredList, defaultSet.bigFortune)
  );

  const [yearMonthDay, setYearMonthDay] = useState(
    convertDateString(birthday) ?? getYMDString(new Date())
  );
  const [hourMin, setHourMin] = useState(
    convertTimeString(time) ?? getHMString(new Date())
  );
  const [birthType, setBirthType] = useState(birthDayType);
  const [gender, setGender] = useState(genderType);

  const [currentBigFortune, setCurrentBigFortune] = useState<number | null>(
    null
  );
  const [currentSmallFortune, setCurrentSmallFortune] = useState<number | null>(
    null
  );
  const [currentMonthFortune, setCurrentMonthFortune] = useState<number | null>(
    null
  );
  const [inputDisabled, setInputDisabled] = useState(false);
  const [currentTimeOption, setCurrentTimeOption] = useState("default");
  const [currentYearText, setCurrentYearText] = useState<string>("??");

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;

    if (value === "default") {
      setInputDisabled(false);
      setCurrentTimeOption(value);
    } else {
      setInputDisabled(true);
      setCurrentTimeOption(value);
      setHourMin(value);
    }
  };

  const onClickSaveButton = () => {
    // 로그인한 회원이라면
    if (authState) {
      saveDefaultUser({
        nickName: null,
        birthday: yearMonthDay,
        time: hourMin,
        birthDayType: birthType,
        genderType: gender,
        unknownTime: hourMin === "????" ? true : false,
      });
      navigate("/MyPage");
    } else {
      // 로그인하지 않은 회원이라면 로그인 페이지로 이동.
      navigate("/login");
    }
  };

  const onClickSeeFortune = () => {
    if (hourMin.length !== 4 || yearMonthDay.length !== 8) {
      alert("입력값을 확인하세요.");
      return;
    }
    getSaju
      .get<Root>(
        urlNav({
          url: `/mobile/manse`,
          gender: gender,
          birthType: birthType,
          yearMonthDay: yearMonthDay,
          hourMin: hourMin,
          currentSmallFortune: getCurrentYear(),
          unknownTime: hourMin === "????" ? true : false,
        })
      )
      .then((resolve) => {
        const { saju, hundredList, bigFortune } = resolve.data;
        setCurrentBigFortune(saju.fortune.big);
        setCurrentSmallFortune(saju.fortune.small);
        setCurrentMonthFortune(getCurrentMonth());
        setResult(resolve.data);
        setHundredList(createSmallFortuneTableArray(hundredList, bigFortune));
        loadDefaultUser({
          genderType: gender,
          nickName: null,
          birthDayType: birthType,
          time: hourMin,
          birthday: yearMonthDay,
          unknownTime: hourMin === "????" ? true : false,
        });
      })
      .catch(function (error) {
        let errorMessage = error.message; // default to the generic error message

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        alert(errorMessage ?? "유효하지 않은 값입니다.");
      });
  };

  const onClickBigFortune = (value: number) => {
    //console.log("onClickBigFortune", value);
    setCurrentBigFortune(value);
    setCurrentSmallFortune(null);
    setCurrentMonthFortune(null);
  };
  const onClickSmallFortune = (value: number) => {
    if (currentBigFortune) {
      setCurrentSmallFortune(value);
      setCurrentMonthFortune(null);
      console.log(unknownTime);
      getSaju
        .get<Root>(
          urlNav({
            url: `/mobile/manse`,
            gender: gender,
            birthType: birthType,
            yearMonthDay: yearMonthDay,
            hourMin: hourMin,
            unknownTime: unknownTime,
            currentBigFortune: currentBigFortune,
            currentSmallFortune: value,
          })
        )
        .then((resolve) => {
          const { monthFortune } = resolve.data;
          setResult((prev) => {
            return { ...prev, monthFortune };
          });
        })
        .catch(function (error) {
          let errorMessage = error.message; // default to the generic error message

          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
          }

          alert(errorMessage ?? "유효하지 않은 값입니다.");
        });
    }
  };

  const onClickMonthFortune = (value: number) => {
    if (currentBigFortune && currentSmallFortune) {
      getSaju
        .get<Root>(
          urlNav({
            url: `/mobile/manse`,
            gender: gender,
            birthType: birthType,
            yearMonthDay: yearMonthDay,
            hourMin: hourMin,
            unknownTime: unknownTime,
            currentBigFortune: currentBigFortune,
            currentSmallFortune: currentSmallFortune,
            currentMonthFortune: value,
          })
        )
        .then((resolve) => {
          const { calender, hundredList } = resolve.data;
          //console.log("calender", calender.calenderMonth);
          setCurrentYearText(
            `${
              hundredList.sky[hundredList.year.indexOf(currentSmallFortune)]
                ?.chinese
            }${
              hundredList.ground[hundredList.year.indexOf(currentSmallFortune)]
                ?.chinese
            }`
          );
          setCurrentMonthFortune(value);
          setResult((prev) => {
            return { ...prev, calender };
          });
        })
        .catch(function (error) {
          let errorMessage = error.message; // default to the generic error message

          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
          }

          alert(errorMessage ?? "유효하지 않은 값입니다.");
        });
    }
  };

  const onClickHundredTable = (bigNum: number, smallNum: number) => {
    // //console.log("onClickHundredTable::", bigNum);

    setCurrentBigFortune(bigNum);
    setCurrentSmallFortune(smallNum);
    setCurrentMonthFortune(null);

    getSaju
      .get<Root>(
        urlNav({
          url: `/mobile/manse`,
          gender: gender,
          birthType: birthType,
          yearMonthDay: yearMonthDay,
          hourMin: hourMin,
          unknownTime: unknownTime,
          currentBigFortune: bigNum,
          currentSmallFortune: smallNum,
        })
      )
      .then((resolve) => {
        const { monthFortune, hundredList } = resolve.data;
        setResult((prev) => {
          return { ...prev, monthFortune, hundredList };
        });
      })
      .catch(function (error) {
        let errorMessage = error.message; // default to the generic error message

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        alert(errorMessage ?? "유효하지 않은 값입니다.");
      });
  };

  useEffect(() => {
    getSaju
      .get<Root>(
        urlNav({
          url: `/mobile/manse`,
          gender: gender,
          birthType: birthType,
          yearMonthDay: yearMonthDay,
          hourMin: hourMin,
          unknownTime: unknownTime,
          currentSmallFortune: getCurrentYear(),
          currentMonthFortune: getCurrentMonth(),
        })
      )
      .then((resolve) => {
        const { saju, hundredList, bigFortune } = resolve.data;
        setCurrentYearText(
          `${
            hundredList.sky[hundredList.year.indexOf(saju.fortune.small)]
              ?.chinese
          }${
            hundredList.ground[hundredList.year.indexOf(saju.fortune.small)]
              ?.chinese
          }`
        );
        setCurrentSmallFortune(saju.fortune.small);
        setCurrentBigFortune(saju.fortune.big);
        setCurrentMonthFortune(getCurrentMonth());
        setResult(resolve.data);
        setHundredList(createSmallFortuneTableArray(hundredList, bigFortune));
      })
      .catch(function (error) {
        let errorMessage = error.message; // default to the generic error message

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        alert(errorMessage ?? "유효하지 않은 값입니다.");
      })
      .finally(() => {
        console.log("loadingFinish");
        setLoading(false);
      });
  }, []);

  return (
    //wrapper
    <div className="flex justify-center w-full min-h-full">
      {/* 가로모드 좌측 빈여백 */}
      {/* <div className="sm:w-[27%] sm:visible" /> */}

      {/* 메인 내용 */}
      <div className="relative sm:w-full lg:w-[70%] max-w-[716px] max-lg:px-2">
        <div className="absolute right-0 max-sm:pr-2 top-4">
          <Menu printRef={printRef} />
        </div>
        {/* 이규호 만세력 header */}
        <div className="w-full" ref={printRef}>
          <div className="flex w-full  py-[11px]  justify-center sm:justify-start ">
            <button
              style={{
                fontSize: "max(32px,1vw)",

                fontFamily: "MapoGoldenPier",
                fontWeight: 800,
              }}
              onClick={() => {
                reset();
                window.location.assign("/");
              }}
            >
              이규호 만세력
            </button>
          </div>

          {/* 사주 정보 입력창  */}
          <div className="grid grid-cols-2 gap-1">
            {/* 년월일 시입력 시선택 */}
            <div className="grid grid-cols-2 gap-1">
              <div className="grid grid-cols-5 col-span-2 gap-1 sm:col-span-1">
                <CustomInput
                  maxLength={8}
                  type={"input"}
                  name={"yearMonthDay"}
                  label={"년월일"}
                  placeholder="20230704"
                  className=" col-span-3 h-[54px] "
                  value={yearMonthDay}
                  onChange={(e) => {
                    const newValue = e.currentTarget.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    setYearMonthDay(newValue);
                  }}
                />
                <CustomInput
                  maxLength={4}
                  type={"text"}
                  name={"hourMin"}
                  label={"시 입력"}
                  disabled={inputDisabled}
                  placeholder="1522"
                  className=" col-span-2 h-[54px]"
                  value={hourMin}
                  onChange={(e) => {
                    const newValue = e.currentTarget.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    setHourMin(newValue);
                  }}
                />
              </div>
              {/* 시선택 */}
              <CustomSelectChoose
                title="시 선택"
                options={timeOptionsMain}
                value={currentTimeOption}
                onChange={handleChange}
                className="col-span-2 sm:col-span-1 h-[54px]"
              />
            </div>
            {/* 양음윤, 성별, 저장, 사주보기 */}
            <div className="grid grid-cols-2 gap-1">
              {/* 양음윤, 성별*/}
              <div className="grid grid-cols-2 col-span-2 gap-1 sm:col-span-1">
                <CustomSelect
                  title="양,음,윤"
                  options={[
                    { key: "양력", value: "SOLAR" },
                    { key: "음력", value: "LUNAR" },
                    { key: "윤달", value: "LEAP" },
                  ]}
                  value={birthType}
                  onChange={(e) => {
                    setBirthType(e.currentTarget.value);
                  }}
                  className="col-span-1 h-[54px]"
                />
                <CustomSelect
                  title="성별"
                  options={[
                    { key: "남", value: "MALE" },
                    { key: "여", value: "FEMALE" },
                  ]}
                  value={gender}
                  onChange={(e) => {
                    setGender(e.currentTarget.value as GENDERTYPE);
                  }}
                  className=" col-span-1 h-[54px]"
                />
              </div>
              {/* 저장, 사주보기 */}
              <div className="grid grid-cols-3 col-span-2 gap-1 sm:col-span-1">
                <CustomButton
                  onClick={onClickSaveButton}
                  primaryType={false}
                  disabled={hourMin.length !== 4 || yearMonthDay.length !== 8}
                  text="저장"
                  className=" col-span-1 h-[54px] font-extrabold"
                />
                <CustomButton
                  onClick={onClickSeeFortune}
                  type="submit"
                  primaryType={true}
                  // disabled={hourMin.length !== 4 || yearMonthDay.length !== 8}
                  text="사주보기"
                  className=" col-span-2  h-[54px] font-extrabold"
                />
              </div>
            </div>
          </div>
          {/* 가로 선 */}
          <div className="w-full h-[1px] bg-[#DCDCDC] my-2" />

          {/* 사주 테이블, 대운 테이블, 세운 테이블, 월운 테이블, 달력 테이블 */}
          <div className="flex w-full gap-3 max-sm:flex-col">
            {/* 사주 테이블, 대운 테이블, 세운 테이블, 월운 테이블*/}
            <div className="w-full sm:w-1/2">
              {/* 사주 테이블 */}
              <div className="flex flex-col items-center w-full">
                <ClientInfo
                  loading={loading}
                  nickName={nickName}
                  genderType={genderType}
                  birthday={birthday}
                  birthDayType={birthDayType}
                  seasonName={result.seasonName}
                  summmerTime={result.summmerTime}
                  unknownTime={result.unknownTime}
                  solarDate={result.solarBirthDay}
                  lunaDate={result.lunarBirthDay}
                  age={result.currentAge}
                />

                {/* 사주 테이블 */}
                <SajuTableV2
                  saju={result.saju}
                  loading={loading}
                  unknownTime={result.unknownTime}
                />
                <div className="w-full h-[2px] bg-[#3C3C3C] my-3 sm:bg-transparent sm:my-1" />
                {/* 대운 테이블 */}
                <BigFortuneTable
                  loading={loading}
                  bigFortuneProps={result.bigFortune}
                  currentBigFortune={currentBigFortune}
                  onClickBigFortune={onClickBigFortune}
                />
                <div className="w-full h-[2px] bg-[#3C3C3C] my-3 sm:bg-transparent sm:my-1" />
                {/* 세운 테이블 */}
                <SmallFortuneTableV3
                  loading={loading}
                  // smallFortune={result.smallFortune}
                  currentSmallFortune={currentSmallFortune}
                  onClickSmallFortune={onClickSmallFortune}
                  hundredList={hundredList}
                  currentBigFortune={currentBigFortune}
                />
                <div className="w-full h-[2px] bg-[#3C3C3C] my-3 sm:bg-transparent sm:my-1" />
                {/* 월운 테이블 */}
                <MonthFortuneTable
                  loading={loading}
                  monthFortune={result.monthFortune}
                  currentMonthFortune={currentMonthFortune}
                  onClickMonthFortune={onClickMonthFortune}
                />
                <div className="w-full h-[2px] bg-[#3C3C3C] my-3 sm:bg-transparent sm:my-1" />
              </div>
            </div>
            <div className="flex flex-col items-center w-full bg-blue-200/0 sm:w-1/2">
              {isMobile ? (
                <>
                  <div className="relative w-full rounded-xl bg-[#E9E9E9] flex flex-col items-center justify-center h-[56px] mb-2 font-extrabold">
                    {!loading ? (
                      result.calender.calenderYear + `(${currentYearText}年)`
                    ) : (
                      <ShimmerEffect />
                    )}
                  </div>
                  <Calendar
                    loading={loading}
                    calenderYear={result.calender.calenderYear}
                    calenderMonth={result.calender.calenderMonth}
                  />
                  <img
                    src={SummerTimeTable}
                    className="w-full mt-2"
                    alt="summer-time-table"
                  />
                </>
              ) : (
                <>
                  <div className="flex items-end justify-between w-full gap-2 min-h-[56px] mb-2">
                    {showCalendar ? (
                      <div className="w-3/4 rounded-xl min-h-[56px] bg-[#E9E9E9] flex flex-col items-center justify-center font-extrabold">
                        {
                          result.hundredList.year[
                            result.hundredList.age.findIndex((age) => age === 0)
                          ]
                        }
                        년~
                        {
                          result.hundredList.year[
                            result.hundredList.year.length - 1
                          ]
                        }
                        년 일년운
                      </div>
                    ) : (
                      <div className="overflow-hidden relative w-3/4 h-[56px] rounded-xl bg-[#E9E9E9] font-extrabold flex flex-col items-center justify-center ">
                        {!loading ? (
                          result.calender.calenderYear +
                          `(${currentYearText}年)`
                        ) : (
                          <ShimmerEffect />
                        )}
                      </div>
                    )}
                    <div className="flex flex-col items-start justify-end w-1/4 text-xs ">
                      <div className="flex gap-1">
                        <input
                          type="radio"
                          id="dewey"
                          name="drone"
                          value="dewey"
                          checked={!showCalendar}
                          onChange={() => setShowCalendar(false)}
                        />
                        <label htmlFor="dewey">달력</label>
                      </div>
                      <div className="flex gap-1">
                        <input
                          type="radio"
                          id="huey"
                          name="drone"
                          value="huey"
                          checked={showCalendar}
                          onChange={() => setShowCalendar(true)}
                        />
                        <label htmlFor="huey">일년운</label>
                      </div>
                    </div>
                  </div>
                  {!showCalendar ? (
                    <>
                      <Calendar
                        loading={loading}
                        calenderYear={result.calender.calenderYear}
                        calenderMonth={result.calender.calenderMonth}
                      />
                      <img
                        src={SummerTimeTable}
                        className="w-full mt-2"
                        alt="summer-time-table"
                      />
                    </>
                  ) : (
                    <HundredTable
                      bigFortuneProps={result.bigFortune}
                      hundredList={result.hundredList}
                      currentSmallFortune={currentSmallFortune}
                      // onClickSmallFortune={onClickSmallFortune}
                      onClickHundredTable={onClickHundredTable}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* 가로모드 우측 빈여백 */}
      {/* <div className="sm:w-[27%] sm:visible" /> */}
    </div>
  );
}
