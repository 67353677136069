import { Saju } from "../../utils/types";
import ShimmerEffect from "../Shimmer";
import "../Shimmer/ShimmerEffect.css";

/** 사주보기 페이지 */
export function SajuTableV2({
  saju,
  loading,
  unknownTime,
}: {
  saju: Saju;
  loading: boolean;
  unknownTime: boolean;
}) {
  return (
    <table
      className="w-full overflow-hidden"
      style={{
        fontWeight: 800,
        borderRadius: 6,
        borderStyle: "hidden",
        boxShadow: "0 0 0 2px #3C3C3C",
      }}
    >
      <tbody className="grid w-full grid-cols-1">
        <tr className=" col-span-1 bg-[#E7E7E7] font-extraBold grid grid-cols-4 gap-0 ">
          <th className="col-span-1 flex w-full h-full items-center justify-center text-center border border-[#3C3C3C] aspect-[4/1]">
            시
          </th>
          <th className="col-span-1 flex w-full h-full items-center justify-center text-center border border-[#3C3C3C] aspect-[4/1]">
            일
          </th>
          <th className="col-span-1 flex w-full h-full items-center justify-center text-center border border-[#3C3C3C] aspect-[4/1]">
            월
          </th>
          <th className="col-span-1 flex w-full h-full items-center justify-center text-center border border-[#3C3C3C] aspect-[4/1]">
            년
          </th>
        </tr>
        <tr className="grid w-full grid-cols-4 col-span-1">
          {saju.sky_tenStart.map((item, index) => (
            <td
              className="relative col-span-1 border border-[#3C3C3C] aspect-[4/1] flex items-center justify-center w-full h-full"
              key={`sky-ten-star-${index}`}
            >
              {!loading ? (
                <>
                  <div className="sm:hidden">
                    {/* 한글 */}
                    {unknownTime && index === 0 ? "?" : item[0]}
                  </div>
                  <div
                    className="max-sm:hidden"
                    style={{ fontFamily: "KPACJKSC" }}
                  >
                    {/* 한자 */}
                    {unknownTime && index === 0 ? "?" : item[1]}
                  </div>
                </>
              ) : (
                <ShimmerEffect />
              )}
            </td>
          ))}
        </tr>
        <tr className="grid w-full grid-cols-4">
          {saju.sky.map((item, index) => (
            <td
              className="relative col-span-1 border border-[#3C3C3C] font-bold aspect-square text-[56px]"
              key={`sky-${index}`}
              style={{
                //폰트 변경필요
                backgroundColor:
                  (!loading && unknownTime === false) || index !== 0
                    ? item.color
                    : "transparent",

                fontWeight: 700,
                color:
                  index === 0 && unknownTime
                    ? "black"
                    : item.color === "#727272"
                    ? "white"
                    : "black",
                fontFamily: "KPACJKSC",
              }}
            >
              {!loading ? (
                <div className="flex items-center justify-center w-full h-full">
                  {unknownTime && index === 0 ? "?" : item.chinese}
                </div>
              ) : (
                <ShimmerEffect />
              )}
            </td>
          ))}
        </tr>
        <tr className="grid w-full grid-cols-4">
          {saju.ground.map((item, index) => (
            <td
              className="relative col-span-1 aspect-square border border-[#3C3C3C] font-bold text-[56px]"
              key={`ground-${index}`}
              style={{
                //폰트 변경필요
                backgroundColor:
                  (!loading && unknownTime === false) || index !== 0
                    ? item.color
                    : "transparent",
                fontWeight: 700,
                color:
                  index === 0 && unknownTime
                    ? "black"
                    : item.color === "#727272"
                    ? "white"
                    : "black",
                fontFamily: "KPACJKSC",
              }}
            >
              {!loading ? (
                <div className="flex items-center justify-center w-full h-full">
                  {unknownTime && index === 0 ? "?" : item.chinese}
                </div>
              ) : (
                <ShimmerEffect />
              )}
            </td>
          ))}
        </tr>
        {/* 지간 십이운성 */}
        <tr className="grid w-full grid-cols-4">
          {saju.ground_tenstar.map((item, index) => (
            <td
              className="relative col-span-1 border text-[14px] border-[#3C3C3C] font-extrabold sm:font-bold aspect-[4/1] "
              key={`ground-tenstar-${index}`}
            >
              {!loading ? (
                <div className="flex items-center justify-around h-full">
                  <span className="sm:hidden">
                    {unknownTime && index === 0 ? "?" : item[0]}
                  </span>
                  <span
                    className="max-sm:hidden"
                    style={{ fontFamily: "KPACJKSC" }}
                  >
                    {unknownTime && index === 0 ? "?" : item[1]}
                  </span>
                  <span
                    style={{
                      color: unknownTime && index === 0 ? "black" : "#3C8AFF",
                    }}
                    className="sm:hidden"
                  >
                    {unknownTime && index === 0
                      ? "?"
                      : saju.twelfthStars[index].chinese}
                  </span>
                  <span
                    style={{
                      color: unknownTime && index === 0 ? "black" : "#3C8AFF",
                      fontFamily: "KPACJKSC",
                    }}
                    className="max-sm:hidden"
                  >
                    {unknownTime && index === 0
                      ? "?"
                      : saju.twelfthStars[index].chineseCharacter}
                  </span>
                </div>
              ) : (
                <ShimmerEffect />
              )}
            </td>
          ))}
        </tr>
        {/* 지장간 */}
        <tr className="grid w-full grid-cols-4 ">
          {saju.jijanggan.map((item, index) => (
            <td
              className="relative col-span-1 border border-[#3C3C3C] aspect-[4/1] sm:aspect-[2.5/1] font-bold flex items-center"
              key={`ground-jijanggan-${index}`}
            >
              {!loading ? (
                <div className="flex items-center justify-center w-full h-full ">
                  {item
                    .filter((data) => Object.keys(data).length > 0)
                    .map((data, idx) => (
                      <div
                        key={`jijanggan-${index}-${idx}`}
                        className="flex items-center justify-center w-[25%] sm:w-[35%] aspect-square -ml-[6%] rounded-full  first:ml-0"
                        style={{
                          backgroundColor:
                            index === 0 && unknownTime
                              ? "transparent"
                              : data.color,
                          border:
                            (index === 0 && unknownTime) ||
                            data.color === "#FFFFFF"
                              ? "1px black solid"
                              : "",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontFamily: "KPACJKSC",
                            color:
                              index === 0 && unknownTime
                                ? "black"
                                : data.color === "#727272"
                                ? "white"
                                : "black",
                          }}
                        >
                          {unknownTime && index === 0 ? "?" : data.chinese}
                        </span>
                      </div>
                    ))}
                </div>
              ) : (
                <ShimmerEffect />
              )}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
/** 궁합보기 페이지 */
export function SajuTableV3({
  saju,
  loading,
  unknownTime,
}: {
  saju: Saju;
  loading: boolean;
  unknownTime: boolean;
}) {
  return (
    <table
      className="w-full overflow-hidden"
      style={{
        fontWeight: 800,
        borderRadius: 6,
        borderStyle: "hidden",
        boxShadow: "0 0 0 2px #3C3C3C",
      }}
    >
      <tbody className="grid w-full grid-cols-1 text-xs">
        <tr className=" col-span-1 bg-[#E7E7E7] font-extraBold grid grid-cols-4 gap-0 ">
          <th className="col-span-1 flex w-full h-full items-center justify-center text-center border border-[#3C3C3C] aspect-[4/1]">
            시
          </th>
          <th className="col-span-1 flex w-full h-full items-center justify-center text-center border border-[#3C3C3C] aspect-[4/1]">
            일
          </th>
          <th className="col-span-1 flex w-full h-full items-center justify-center text-center border border-[#3C3C3C] aspect-[4/1]">
            월
          </th>
          <th className="col-span-1 flex w-full h-full items-center justify-center text-center border border-[#3C3C3C] aspect-[4/1]">
            년
          </th>
        </tr>
        <tr className="grid grid-cols-4 col-span-1 text-xs sm:text-lg">
          {saju.sky_tenStart.map((item, index) => (
            <td
              className="relative col-span-1 border border-[#3C3C3C] aspect-[2/1] sm:aspect-[4/1]"
              key={`sky-ten-star-${index}`}
            >
              {!loading ? (
                <>
                  <div className="flex items-center justify-center h-full sm:hidden">
                    {/* 한글 */}
                    {unknownTime && index === 0 ? "?" : item[0]}
                  </div>
                  <div
                    className="flex items-center justify-center h-full max-sm:hidden"
                    style={{ fontFamily: "KPACJKSC" }}
                  >
                    {/* 한자 */}
                    {unknownTime && index === 0 ? "?" : item[1]}
                  </div>
                </>
              ) : (
                <ShimmerEffect />
              )}
            </td>
          ))}
        </tr>
        <tr className="grid w-full grid-cols-4 text-4xl sm:text-5xl">
          {saju.sky.map((item, index) => (
            <td
              className="relative col-span-1 border border-[#3C3C3C] font-bold aspect-square"
              key={`sky-${index}`}
              style={{
                //폰트 변경필요
                backgroundColor:
                  (!loading && unknownTime === false) || index !== 0
                    ? item.color
                    : "transparent",

                fontWeight: 700,
                color:
                  index === 0 && unknownTime
                    ? "black"
                    : item.color === "#727272"
                    ? "white"
                    : "black",
                fontFamily: "KPACJKSC",
              }}
            >
              {!loading ? (
                <div className="flex items-center justify-center w-full h-full">
                  {unknownTime && index === 0 ? "?" : item.chinese}
                </div>
              ) : (
                <ShimmerEffect />
              )}
            </td>
          ))}
        </tr>
        <tr className="grid w-full grid-cols-4 text-4xl sm:text-5xl">
          {saju.ground.map((item, index) => (
            <td
              className="relative col-span-1 aspect-square border border-[#3C3C3C] font-bold"
              key={`ground-${index}`}
              style={{
                //폰트 변경필요
                backgroundColor:
                  (!loading && unknownTime === false) || index !== 0
                    ? item.color
                    : "transparent",
                fontWeight: 700,
                color:
                  index === 0 && unknownTime
                    ? "black"
                    : item.color === "#727272"
                    ? "white"
                    : "black",
                fontFamily: "KPACJKSC",
              }}
            >
              {!loading ? (
                <div className="flex items-center justify-center w-full h-full">
                  {unknownTime && index === 0 ? "?" : item.chinese}
                </div>
              ) : (
                <ShimmerEffect />
              )}
            </td>
          ))}
        </tr>
        {/* 지간 십이운성 */}
        <tr className="grid w-full grid-cols-4 text-[10px] sm:text-base">
          {saju.ground_tenstar.map((item, index) => (
            <td
              className="relative col-span-1 border border-[#3C3C3C] font-extrabold sm:font-bold aspect-[2/1] sm:aspect-[3/1]"
              key={`ground-tenstar-${index}`}
            >
              {!loading ? (
                <div className="flex items-center justify-around h-full">
                  <span className="sm:hidden">
                    {unknownTime && index === 0 ? "?" : item[0]}
                  </span>
                  <span
                    className="max-sm:hidden"
                    style={{ fontFamily: "KPACJKSC" }}
                  >
                    {unknownTime && index === 0 ? "?" : item[1]}
                  </span>
                  <span
                    style={{
                      color: unknownTime && index === 0 ? "black" : "#3C8AFF",
                    }}
                    className="sm:hidden"
                  >
                    {unknownTime && index === 0
                      ? "?"
                      : saju.twelfthStars[index].chinese}
                  </span>
                  <span
                    style={{
                      color: unknownTime && index === 0 ? "black" : "#3C8AFF",
                      fontFamily: "KPACJKSC",
                    }}
                    className="max-sm:hidden"
                  >
                    {unknownTime && index === 0
                      ? "?"
                      : saju.twelfthStars[index].chineseCharacter}
                  </span>
                </div>
              ) : (
                <ShimmerEffect />
              )}
            </td>
          ))}
        </tr>
        {/* 지장간 */}
        <tr className="grid w-full grid-cols-4 text-[10px] sm:text-base">
          {saju.jijanggan.map((item, index) => (
            <td
              className="relative col-span-1 border border-[#3C3C3C] aspect-[2/1] font-bold flex items-center"
              key={`ground-jijanggan-${index}`}
            >
              {!loading ? (
                <div className="flex items-center justify-center w-full h-full ">
                  {item
                    .filter((data) => Object.keys(data).length > 0)
                    .map((data, idx) => (
                      <div
                        key={`jijanggan-${index}-${idx}`}
                        className="flex items-center justify-center w-[45%] aspect-square -ml-[12%] rounded-full  first:ml-0"
                        style={{
                          backgroundColor:
                            index === 0 && unknownTime
                              ? "transparent"
                              : data.color,
                          border:
                            (index === 0 && unknownTime) ||
                            data.color === "#FFFFFF"
                              ? "1px black solid"
                              : "",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontFamily: "KPACJKSC",
                            color:
                              index === 0 && unknownTime
                                ? "black"
                                : data.color === "#727272"
                                ? "white"
                                : "black",
                          }}
                        >
                          {unknownTime && index === 0 ? "?" : data.chinese}
                        </span>
                      </div>
                    ))}
                </div>
              ) : (
                <ShimmerEffect />
              )}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
