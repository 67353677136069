import { format, parse } from "date-fns";

export const getYMDString = (date: Date): string => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1.
  const dd = String(date.getDate()).padStart(2, "0");

  // Return as "YYYYMMDD" string
  return `${yyyy}${mm}${dd}`;
};

export function getHMString(date: Date): string {
  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();

  // Convert to 24-hour format
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  // Return as "HHMM" string
  return `${hours}${minutes}`;
}

export function formatDatetoKoreanDate(dateString: string) {
  const parts = dateString.split("-");

  // if (parts.length !== 3) {
  //   throw new Error("Invalid date format");
  // }

  return `${parts[0]}년 ${parts[1]}월 ${parts[2]}일`;
}

export function formatDateWithDash(dateString: string) {
  // '19970125' 형식의 문자열을 '1997-01-25'로 변환
  const parsedDate = parse(dateString, "yyyyMMdd", new Date());
  return format(parsedDate, "yyyy-MM-dd");
}
export function formatTimeWithColon(timeString: string) {
  // '1000' 형식의 문자열을 '10:00'으로 변환
  const parsedTime = parse(timeString, "HHmm", new Date());
  return format(parsedTime, "HH:mm");
}
export const convertTimeString = (time: string | null): string | null => {
  if (time === null) {
    return null;
  }

  // Remove the ":" from the time string
  return time.replace(":", "");
};
export const convertDateString = (date: string | null): string | null => {
  if (date === null) {
    return null;
  }

  // Remove the "-" from the date string
  return date.replace(/-/g, "");
};

export const formatDate = (date: string): string => {
  if (date.length !== 8) {
    // throw new Error(
    //   "Invalid date format. The date string should be in YYYYMMDD format."
    // );
    return "";
  }

  const yyyy = date.substring(0, 4);
  const mm = date.substring(4, 6);
  const dd = date.substring(6, 8);

  return `${yyyy}년 ${mm}월 ${dd}일`;
};

export function parseDateString(dateString: string) {
  const yearMatch = dateString.match(/\d{4}년/);
  const monthMatch = dateString.match(/\d{1,2}월/);

  if (!yearMatch || !monthMatch) {
    throw new Error("Invalid date string format");
  }

  const year = parseInt(yearMatch[0], 10);
  const month = parseInt(monthMatch[0], 10) - 1; // 월은 0부터 시작하므로 1을 빼줍니다.

  return new Date(year, month);
}

/** 현재년도의 월을 반환하는 함수 */
export const getCurrentMonth = () => {
  const date = new Date();
  return date.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더합니다.
};
/** 현재년도를 반환하는 함수 */
export const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear();
};

export function formatBirthday(birthday: string) {
  // 하이픈(-)이 있는 경우
  if (birthday.includes("-")) {
    return birthday.replace(/-/g, ".");
  }
  // 하이픈(-)이 없는 경우
  else if (birthday.length === 8) {
    return `${birthday.substring(0, 4)}.${birthday.substring(
      4,
      6
    )}.${birthday.substring(6, 8)}`;
  }
  // 유효하지 않은 형식
  else {
    return "Invalid date format";
  }
}

/** 음력의 표시는 1일인 경우에만 월과 일을 같이 표시하고 아닌 경우에는 날짜만 표시한다. */
export function convertLunaDate(str: string) {
  const parts = str.split(".");
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  if (day === 1) {
    return `${month}.${day}`;
  } else {
    return day;
  }
}
