import React, { useEffect, useRef } from "react";

interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  label: string;
  className: string;
}

export default function CustomInput(props: InputProps) {
  const { label, className } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current;

      const handleFocus = () => {
        // 입력 값의 길이를 가져와서 커서를 이동
        input.value = "";
        const length = input.value.length;
        input.setSelectionRange(length, length);
      };

      input.addEventListener("focus", handleFocus);

      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      return () => {
        input.removeEventListener("focus", handleFocus);
      };
    }
  }, []);

  return (
    <div
      className={
        "relative px-1 py-2 border-2 border-slate-400  flex items-center " +
        className
      }
      style={{ borderRadius: 6 }}
    >
      <span
        className="absolute bg-white leading-none -top-2 sm:-top-[0.5vw] left-1 text-slate-400 text-xs"
        // style={{ fontSize: 12 }}
      >
        {label}
      </span>
      <input
        ref={inputRef}
        {...props}
        className="w-full text-left outline-none"
      />
    </div>
  );
}

/** 궁합페이지 입력창 */
export function CustomInputV2(props: InputProps) {
  const { label, className } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current;

      const handleFocus = () => {
        // 입력 값의 길이를 가져와서 커서를 이동
        input.value = "";
        const length = input.value.length;
        input.setSelectionRange(length, length);
      };

      input.addEventListener("focus", handleFocus);

      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      return () => {
        input.removeEventListener("focus", handleFocus);
      };
    }
  }, []);

  return (
    <div
      className={
        "relative rounded-[4px] sm:rounded-md pl-[3px] sm:pl-[3px] py-1 border-[0.1vw] sm:border-[1.8px] border-slate-400 flex items-center " +
        className
      }
    >
      <span className="absolute bg-white leading-none -top-1 sm:-top-[0.5vw] left-1 text-slate-400 text-[7px] sm:text-xs">
        {label}
      </span>
      <input
        ref={inputRef}
        {...props}
        className="w-full text-xs text-left bg-transparent outline-none sm:text-base"
      />
    </div>
  );
}
/** 마이페이지 입력창 */
export function CustomInputV3(props: InputProps) {
  const { label, className } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current;

      const handleFocus = () => {
        // 입력 값의 길이를 가져와서 커서를 이동
        const length = input.value.length;
        input.setSelectionRange(length, length);
      };

      input.addEventListener("focus", handleFocus);

      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      return () => {
        input.removeEventListener("focus", handleFocus);
      };
    }
  }, []);

  return (
    <div
      className={
        "relative px-[6px] py-1 border-[1.8px] border-slate-400  flex items-center" +
        className
      }
      style={{ borderRadius: 6 }}
    >
      <span className="absolute bg-white -top-3 left-2 text-slate-400 text-md">
        {label}
      </span>
      <input
        ref={inputRef}
        {...props}
        className="w-full outline-none text-md "
      />
    </div>
  );
}
