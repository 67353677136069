export const defaultSet = {
  saju: {
    sky_tenStart: [
      ["편인", "偏印"],
      ["비견", "比肩"],
      ["편관", "偏官"],
      ["편인", "偏印"],
    ],
    sky: [
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
    ],
    ground: [
      {
        chinese: "寅",
        color: "#C7E570",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
    ],
    ground_tenstar: [
      ["편재", "偏財"],
      ["비견", "比肩"],
      ["편인", "偏印"],
      ["편인", "偏印"],
    ],
    jijanggan: [
      [
        {
          chinese: "戊",
          color: "#FFD43C",
        },
        {
          chinese: "丙",
          color: "#F6987A",
        },
        {
          chinese: "甲",
          color: "#C7E570",
        },
      ],
      [
        {
          chinese: "戊",
          color: "#FFD43C",
        },
        {
          chinese: "壬",
          color: "#727272",
        },
        {
          chinese: "庚",
          color: "#FFFFFF",
        },
      ],
      [
        {
          chinese: "乙",
          color: "#C7E570",
        },
        {
          chinese: "癸",
          color: "#727272",
        },
        {
          chinese: "戊",
          color: "#FFD43C",
        },
      ],
      [
        {
          chinese: "乙",
          color: "#C7E570",
        },
        {
          chinese: "癸",
          color: "#727272",
        },
        {
          chinese: "戊",
          color: "#FFD43C",
        },
      ],
    ],
    fortune: {
      big: 5,
      small: 2023,
    },
    twelfthStars: [
      {
        chinese: "절",
        color: "#C7E570",
        chineseCharacter: "絶",
      },
      {
        chinese: "건록",
        color: "#FFFFFF",
        chineseCharacter: "乾祿",
      },
      {
        chinese: "양",
        color: "#FFFFFF",
        chineseCharacter: "養",
      },
      {
        chinese: "양",
        color: "#FFFFFF",
        chineseCharacter: "養",
      },
    ],
  },
  bigFortune: {
    year: [81, 71, 61, 51, 41, 31, 21, 11, 1, "大"],
    sky: [
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
    ],
    ground: [
      {
        chinese: "丑",
        color: "#FFD43C",
      },
      {
        chinese: "子",
        color: "#727272",
      },
      {
        chinese: "亥",
        color: "#727272",
      },
      {
        chinese: "戌",
        color: "#FFD43C",
      },
      {
        chinese: "酉",
        color: "#ffffff",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
    ],
  },
  smallFortune: {
    year: [2027, 2026, 2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018],
    sky: [
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
    ],
    ground: [
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "卯",
        color: "#C7E570",
      },
      {
        chinese: "寅",
        color: "#C7E570",
      },
      {
        chinese: "丑",
        color: "#FFD43C",
      },
      {
        chinese: "子",
        color: "#727272",
      },
      {
        chinese: "亥",
        color: "#727272",
      },
      {
        chinese: "戌",
        color: "#FFD43C",
      },
    ],
    age: [40, 39, 38, 37, 36, 35, 34, 33, 32, 31],
    lessAge: [39, 38, 37, 36, 35, 34, 33, 32, 31, 30],
  },
  monthFortune: {
    month: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
    sky: [
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
    ],
    ground: [
      {
        chinese: "子",
        color: "#727272",
      },
      {
        chinese: "亥",
        color: "#727272",
      },
      {
        chinese: "戌",
        color: "#FFD43C",
      },
      {
        chinese: "酉",
        color: "#ffffff",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "卯",
        color: "#C7E570",
      },
      {
        chinese: "寅",
        color: "#C7E570",
      },
      {
        chinese: "丑",
        color: "#FFD43C",
      },
    ],
  },
  calender: {
    calenderYear: "2018년 11월 (戊戌年)",
    calenderMonth: [
      {
        solarDate: "2018.11.01",
        lunarDate: "2018.09.24",
        season: null,
        daySky: "丁",
        dayGround: "酉",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.02",
        lunarDate: "2018.09.25",
        season: null,
        daySky: "戊",
        dayGround: "戌",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.03",
        lunarDate: "2018.09.26",
        season: null,
        daySky: "己",
        dayGround: "亥",
        holiday: 1,
        isHoliday: 0,
        dateName: null,
        color: "#6ba3ff",
      },
      {
        solarDate: "2018.11.04",
        lunarDate: "2018.09.27",
        season: null,
        daySky: "庚",
        dayGround: "子",
        holiday: 2,
        isHoliday: 0,
        dateName: null,
        color: "#FF6B73",
      },
      {
        solarDate: "2018.11.05",
        lunarDate: "2018.09.28",
        season: null,
        daySky: "辛",
        dayGround: "丑",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.06",
        lunarDate: "2018.09.29",
        season: null,
        daySky: "壬",
        dayGround: "寅",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.07",
        lunarDate: "2018.09.30",
        season: "입동",
        daySky: "癸",
        dayGround: "卯",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.08",
        lunarDate: "2018.10.01",
        season: null,
        daySky: "甲",
        dayGround: "辰",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.09",
        lunarDate: "2018.10.02",
        season: null,
        daySky: "乙",
        dayGround: "巳",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.10",
        lunarDate: "2018.10.03",
        season: null,
        daySky: "丙",
        dayGround: "午",
        holiday: 1,
        isHoliday: 0,
        dateName: null,
        color: "#6ba3ff",
      },
      {
        solarDate: "2018.11.11",
        lunarDate: "2018.10.04",
        season: null,
        daySky: "丁",
        dayGround: "未",
        holiday: 2,
        isHoliday: 0,
        dateName: null,
        color: "#FF6B73",
      },
      {
        solarDate: "2018.11.12",
        lunarDate: "2018.10.05",
        season: null,
        daySky: "戊",
        dayGround: "申",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.13",
        lunarDate: "2018.10.06",
        season: null,
        daySky: "己",
        dayGround: "酉",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.14",
        lunarDate: "2018.10.07",
        season: null,
        daySky: "庚",
        dayGround: "戌",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.15",
        lunarDate: "2018.10.08",
        season: null,
        daySky: "辛",
        dayGround: "亥",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.16",
        lunarDate: "2018.10.09",
        season: null,
        daySky: "壬",
        dayGround: "子",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.17",
        lunarDate: "2018.10.10",
        season: null,
        daySky: "癸",
        dayGround: "丑",
        holiday: 1,
        isHoliday: 0,
        dateName: null,
        color: "#6ba3ff",
      },
      {
        solarDate: "2018.11.18",
        lunarDate: "2018.10.11",
        season: null,
        daySky: "甲",
        dayGround: "寅",
        holiday: 2,
        isHoliday: 0,
        dateName: null,
        color: "#FF6B73",
      },
      {
        solarDate: "2018.11.19",
        lunarDate: "2018.10.12",
        season: null,
        daySky: "乙",
        dayGround: "卯",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.20",
        lunarDate: "2018.10.13",
        season: null,
        daySky: "丙",
        dayGround: "辰",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.21",
        lunarDate: "2018.10.14",
        season: null,
        daySky: "丁",
        dayGround: "巳",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.22",
        lunarDate: "2018.10.15",
        season: "소설",
        daySky: "戊",
        dayGround: "午",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.23",
        lunarDate: "2018.10.16",
        season: null,
        daySky: "己",
        dayGround: "未",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.24",
        lunarDate: "2018.10.17",
        season: null,
        daySky: "庚",
        dayGround: "申",
        holiday: 1,
        isHoliday: 0,
        dateName: null,
        color: "#6ba3ff",
      },
      {
        solarDate: "2018.11.25",
        lunarDate: "2018.10.18",
        season: null,
        daySky: "辛",
        dayGround: "酉",
        holiday: 2,
        isHoliday: 0,
        dateName: null,
        color: "#FF6B73",
      },
      {
        solarDate: "2018.11.26",
        lunarDate: "2018.10.19",
        season: null,
        daySky: "壬",
        dayGround: "戌",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.27",
        lunarDate: "2018.10.20",
        season: null,
        daySky: "癸",
        dayGround: "亥",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.28",
        lunarDate: "2018.10.21",
        season: null,
        daySky: "甲",
        dayGround: "子",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.29",
        lunarDate: "2018.10.22",
        season: null,
        daySky: "乙",
        dayGround: "丑",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
      {
        solarDate: "2018.11.30",
        lunarDate: "2018.10.23",
        season: null,
        daySky: "丙",
        dayGround: "寅",
        holiday: 0,
        isHoliday: 0,
        dateName: null,
        color: "black",
      },
    ],
  },
  seasonName: "입하",
  summmerTime: 0,
  unknownTime: false,
  solarBirthDay: "1988-05-05",
  lunarBirthDay: "1988-03-20",
  currentAge: 36,
  hundredList: {
    year: [
      1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998,
      1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010,
      2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
      2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034,
      2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046,
      2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058,
      2059, 2060, 2061, 2062, 2063, 2064, 2065, 2066, 2067, 2068, 2069, 2070,
      2071, 2072, 2073, 2074, 2075, 2076, 2077, 2078, 2079, 2080, 2081, 2082,
      2083, 2084, 2085, 2086, 2087, 2088,
    ],
    sky: [
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
      {
        chinese: "己",
        color: "#FFD43C",
      },
      {
        chinese: "庚",
        color: "#ffffff",
      },
      {
        chinese: "辛",
        color: "#ffffff",
      },
      {
        chinese: "壬",
        color: "#727272",
      },
      {
        chinese: "癸",
        color: "#727272",
      },
      {
        chinese: "甲",
        color: "#C7E570",
      },
      {
        chinese: "乙",
        color: "#C7E570",
      },
      {
        chinese: "丙",
        color: "#F6987A",
      },
      {
        chinese: "丁",
        color: "#F6987A",
      },
      {
        chinese: "戊",
        color: "#FFD43C",
      },
    ],
    ground: [
      {
        chinese: "卯",
        color: "#C7E570",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
      {
        chinese: "酉",
        color: "#ffffff",
      },
      {
        chinese: "戌",
        color: "#FFD43C",
      },
      {
        chinese: "亥",
        color: "#727272",
      },
      {
        chinese: "子",
        color: "#727272",
      },
      {
        chinese: "丑",
        color: "#FFD43C",
      },
      {
        chinese: "寅",
        color: "#C7E570",
      },
      {
        chinese: "卯",
        color: "#C7E570",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
      {
        chinese: "酉",
        color: "#ffffff",
      },
      {
        chinese: "戌",
        color: "#FFD43C",
      },
      {
        chinese: "亥",
        color: "#727272",
      },
      {
        chinese: "子",
        color: "#727272",
      },
      {
        chinese: "丑",
        color: "#FFD43C",
      },
      {
        chinese: "寅",
        color: "#C7E570",
      },
      {
        chinese: "卯",
        color: "#C7E570",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
      {
        chinese: "酉",
        color: "#ffffff",
      },
      {
        chinese: "戌",
        color: "#FFD43C",
      },
      {
        chinese: "亥",
        color: "#727272",
      },
      {
        chinese: "子",
        color: "#727272",
      },
      {
        chinese: "丑",
        color: "#FFD43C",
      },
      {
        chinese: "寅",
        color: "#C7E570",
      },
      {
        chinese: "卯",
        color: "#C7E570",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
      {
        chinese: "酉",
        color: "#ffffff",
      },
      {
        chinese: "戌",
        color: "#FFD43C",
      },
      {
        chinese: "亥",
        color: "#727272",
      },
      {
        chinese: "子",
        color: "#727272",
      },
      {
        chinese: "丑",
        color: "#FFD43C",
      },
      {
        chinese: "寅",
        color: "#C7E570",
      },
      {
        chinese: "卯",
        color: "#C7E570",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
      {
        chinese: "酉",
        color: "#ffffff",
      },
      {
        chinese: "戌",
        color: "#FFD43C",
      },
      {
        chinese: "亥",
        color: "#727272",
      },
      {
        chinese: "子",
        color: "#727272",
      },
      {
        chinese: "丑",
        color: "#FFD43C",
      },
      {
        chinese: "寅",
        color: "#C7E570",
      },
      {
        chinese: "卯",
        color: "#C7E570",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
      {
        chinese: "酉",
        color: "#ffffff",
      },
      {
        chinese: "戌",
        color: "#FFD43C",
      },
      {
        chinese: "亥",
        color: "#727272",
      },
      {
        chinese: "子",
        color: "#727272",
      },
      {
        chinese: "丑",
        color: "#FFD43C",
      },
      {
        chinese: "寅",
        color: "#C7E570",
      },
      {
        chinese: "卯",
        color: "#C7E570",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
      {
        chinese: "酉",
        color: "#ffffff",
      },
      {
        chinese: "戌",
        color: "#FFD43C",
      },
      {
        chinese: "亥",
        color: "#727272",
      },
      {
        chinese: "子",
        color: "#727272",
      },
      {
        chinese: "丑",
        color: "#FFD43C",
      },
      {
        chinese: "寅",
        color: "#C7E570",
      },
      {
        chinese: "卯",
        color: "#C7E570",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
      {
        chinese: "酉",
        color: "#ffffff",
      },
      {
        chinese: "戌",
        color: "#FFD43C",
      },
      {
        chinese: "亥",
        color: "#727272",
      },
      {
        chinese: "子",
        color: "#727272",
      },
      {
        chinese: "丑",
        color: "#FFD43C",
      },
      {
        chinese: "寅",
        color: "#C7E570",
      },
      {
        chinese: "卯",
        color: "#C7E570",
      },
      {
        chinese: "辰",
        color: "#FFD43C",
      },
      {
        chinese: "巳",
        color: "#F6987A",
      },
      {
        chinese: "午",
        color: "#F6987A",
      },
      {
        chinese: "未",
        color: "#FFD43C",
      },
      {
        chinese: "申",
        color: "#ffffff",
      },
    ],
    age: [
      -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
      20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
      38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
      56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73,
      74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91,
      92, 93, 94, 95, 96, 97, 98, 99, 100,
    ],
    lessAge: [
      -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
      19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
      37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72,
      73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
      91, 92, 93, 94, 95, 96, 97, 98, 99,
    ],
  },
};
export const generateSecureRandomString = (length = 16) => {
  const randomBuffer = new Uint8Array(length);
  window.crypto.getRandomValues(randomBuffer);
  return Array.from(randomBuffer)
    .map((n) => n.toString(16).padStart(2, "0"))
    .join("");
};

export const timeOptions = [
  { value: "default", key: "숫자" },
  { value: "????", key: "시간 모름" },
  { value: "0030", key: "子時 (23:30~01:29)" },
  { value: "0230", key: "丑時 (01:30~03:29)" },
  { value: "0430", key: "寅時 (03:30~05:29)" },
  { value: "0630", key: "卯時 (05:30~07:29)" },
  { value: "0830", key: "辰時 (07:30~09:29)" },
  { value: "1030", key: "巳時 (09:30~11:29)" },
  { value: "1230", key: "午時 (11:30~13:29)" },
  { value: "1430", key: "未時 (13:30~15:29)" },
  { value: "1630", key: "申時 (15:30~17:29)" },
  { value: "1830", key: "酉時 (17:30~19:29)" },
  { value: "2030", key: "戌時 (19:30~21:29)" },
  { value: "2230", key: "亥時 (21:30~23:29)" },
];
export const timeOptionsMain = [
  { value: "default", key: "숫자로 입력" },
  { value: "????", key: "시간 모름" },
  { value: "0030", key: "子時 (23:30~01:29)" },
  { value: "0230", key: "丑時 (01:30~03:29)" },
  { value: "0430", key: "寅時 (03:30~05:29)" },
  { value: "0630", key: "卯時 (05:30~07:29)" },
  { value: "0830", key: "辰時 (07:30~09:29)" },
  { value: "1030", key: "巳時 (09:30~11:29)" },
  { value: "1230", key: "午時 (11:30~13:29)" },
  { value: "1430", key: "未時 (13:30~15:29)" },
  { value: "1630", key: "申時 (15:30~17:29)" },
  { value: "1830", key: "酉時 (17:30~19:29)" },
  { value: "2030", key: "戌時 (19:30~21:29)" },
  { value: "2230", key: "亥時 (21:30~23:29)" },
];
