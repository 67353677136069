import axios from "axios";
import {
  // getAccecssToken,
  getToken,
  loginTokenKey,
  setItem,
} from "../utils/localStorage";
// import { request } from "http";

export const getSaju = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_LOCALTEST}`
      : `${process.env.REACT_APP_ENDPOINT}`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

/** 2024.05.31
 * - Before
 * 페이지에 새로접속 또는 새로고침을 수행할 때마다 로컬스토리지에 방문일자를 저장하여 현재날짜와 비교하여 방문자수 체크 api 호출.
 *
 * - After
 * 이규호 만세력의 모든 API를 intercept하여 로컬스토리지에 저장된 방문일자를 비교하여 방문자수 체크 API 호출.
 *
 * - 변경 사유.
 * 기존 방식으로 하면 페이지를 새로고침하지 않거나 새로 접속하지 않으면 방문자수 체크 api가 동작하지 않음.
 * 떄문에 기존방식대신 변경된 방법으로 하면 방문자수 체크 api는 하루에 한번만 수행되므로 성능에 영향을 미치지 않음.
 *
 */
const updateVisitDay = () => {
  const storedVisitDay = localStorage.getItem("visitDay");
  const today = new Date();
  const todayDateStr = today.toISOString().split("T")[0];

  if (storedVisitDay) {
    const storedDateStr = new Date(storedVisitDay).toISOString().split("T")[0];

    if (storedDateStr < todayDateStr) {
      localStorage.setItem("visitDay", todayDateStr);
      console.log("today you visit this page first");
      // API 호출
      axios
        .put(`${process.env.REACT_APP_ENDPOINT}/mobile/visit`)
        .catch((error) => console.log(error));
    } else {
      console.log("today you visit this page before");
    }
  } else {
    localStorage.setItem("visitDay", todayDateStr);
    console.log("today you visit this page first");
    axios
      .put(`${process.env.REACT_APP_ENDPOINT}/mobile/visit`)
      .catch((error) => console.log(error));
  }
};
getSaju.interceptors.request.use(
  (request) => {
    updateVisitDay();
    return request;
  },
  async function (error) {
    console.log(error);
  }
);

export const authAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_LOCALTEST}`
      : `${process.env.REACT_APP_ENDPOINT}`,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${getAccecssToken(loginTokenKey)}`,
  },
  withCredentials: true,
});
authAxios.interceptors.response.use(
  (response) => {
    // If the response is okay, just return it
    return response;
  },
  async function (error) {
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      //토큰 갱신 수행.
      const originalRequest = error.config;
      const token = getToken(loginTokenKey);
      if (token) {
        try {
          const { accessToken, refreshToken } = token;
          const resonse = await authAxios.post(`/mobile/auth/refresh`, {
            // accessToken,
            refreshToken,
          });
          if (resonse.status === 200) {
            setItem(loginTokenKey, resonse.data);
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${resonse.data.accessToken}`;
            return await authAxios.request(originalRequest);
          }
        } catch (error) {
          //리프레쉬가 실패하면 499로 감
          localStorage.clear();
          window.location.assign("/");
          return Promise.reject(error);
        }
      }
      // window.location.reload();
      localStorage.clear(); // Replace with the key you use for storing token/data
      window.location.assign("/");
      return Promise.reject(error);
      // If you want to redirect user or perform any other action, do it here
    }

    // Throw the error again so you can catch it elsewhere
    return Promise.reject(error);
  }
);
