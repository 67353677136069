import { MonthFortune } from "../../utils/types";
import ShimmerEffect from "../Shimmer";
const monthList = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
//사주보기 페이지
export default function MonthFortuneTable({
  loading,
  monthFortune,
  currentMonthFortune,
  onClickMonthFortune,
}: {
  loading: boolean;
  monthFortune: MonthFortune;
  currentMonthFortune: number | null;
  onClickMonthFortune: (value: number) => void;
}) {
  return (
    <table
      className="w-full overflow-hidden max-sm:text-[20px] lg:text-[20px]"
      style={{
        fontWeight: 800,
        borderRadius: 6,
        cursor: "pointer",
        borderCollapse: "initial",
      }}
      cellSpacing="0"
    >
      <tbody className="grid w-full grid-cols-1">
        <tr className="w-full bg-[#E7E7E7] col-span-1 grid grid-cols-12 text-base">
          {monthList.map((item, index) => {
            const isCurrentMonthFortune = item === currentMonthFortune;
            return (
              <th
                style={{
                  borderTopLeftRadius: index === 0 ? 6 : 0,
                  borderTopRightRadius: index === 11 ? 6 : 0,
                }}
                key={`month-forturne-year-${item}`}
                className={`col-span-1 border border-t-2 relative aspect-square border-[#3C3C3C]
          
              ${index === 0 && " border-l-2 "}
              ${index === 11 && " border-r-2 "}
              
              `}
                onClick={() => onClickMonthFortune(item)}
              >
                {!loading && (
                  <div className="flex items-center justify-center w-full h-full">
                    {item}
                  </div>
                )}
                {isCurrentMonthFortune && (
                  <div
                    style={{
                      border: "2px solid red",
                      borderBottom: "none",
                    }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </th>
            );
          })}
        </tr>
        <tr className="grid grid-cols-12 col-span-1">
          {monthFortune.sky.map((item, index) => {
            const isCurrentMonthFortune =
              monthList[index] === currentMonthFortune;
            return (
              <td
                key={`month-forturne-sky-${index}`}
                className={`col-span-1 border aspect-square relative border-[#3C3C3C]
              ${index === 0 && " border-l-2 "}
              ${index === 11 && " border-r-2 "}
              `}
                style={{
                  backgroundColor: !loading ? item.color : "none",
                  color: item.color === "#727272" ? "white" : "black",
                  fontFamily: "KPACJKSC",
                }}
                onClick={() => onClickMonthFortune(monthList[index])}
              >
                {!loading && (
                  <div className="flex items-center justify-center w-full h-full ">
                    {item.chinese}
                  </div>
                )}
                {isCurrentMonthFortune && (
                  <div
                    style={{
                      border: "2px solid red",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </td>
            );
          })}
        </tr>
        <tr className="grid grid-cols-12 col-span-1">
          {monthFortune.ground.map((item, index) => {
            const isCurrentMonthFortune =
              monthList[index] === currentMonthFortune;
            return (
              <td
                key={`month-forturne-ground-${index}`}
                className={`overflow-hidden col-span-1 border border-b-2 relative aspect-square border-[#3C3C3C]
              ${index === 0 && " border-l-2 "}
              ${index === 11 && " border-r-2 "}
              `}
                style={{
                  borderBottomLeftRadius: index === 0 ? 6 : 0,
                  borderBottomRightRadius: index === 11 ? 6 : 0,
                  backgroundColor: !loading ? item.color : "none",
                  color: item.color === "#727272" ? "white" : "black",
                  fontFamily: "KPACJKSC",
                }}
                onClick={() => onClickMonthFortune(monthList[index])}
              >
                {!loading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    {item.chinese}
                  </div>
                ) : (
                  <ShimmerEffect />
                )}
                {isCurrentMonthFortune && (
                  <div
                    style={{
                      border: "2px solid red",
                      borderTop: "none",
                    }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}
//궁합보기 페이지
export function MonthFortuneTableV2({
  loading,
  monthFortune,
  currentMonthFortune,
  onClickMonthFortune,
}: {
  loading: boolean;
  monthFortune: MonthFortune;
  currentMonthFortune: number | null;
  onClickMonthFortune: (value: number) => void;
}) {
  return (
    <table
      className="w-full overflow-hidden"
      style={{
        fontWeight: 800,
        borderRadius: 6,
        cursor: "pointer",
        borderCollapse: "initial",
      }}
      cellSpacing="0"
    >
      <tbody className="grid w-full grid-cols-1">
        <tr className="w-full bg-[#E7E7E7] col-span-1 grid grid-cols-12 text-[10px] sm:text-base">
          {monthList.map((item, index) => {
            const isCurrentMonthFortune = item === currentMonthFortune;
            return (
              <th
                style={{
                  borderTopLeftRadius: index === 0 ? 6 : 0,
                  borderTopRightRadius: index === 11 ? 6 : 0,
                }}
                key={`month-forturne-year-${item}`}
                className={`col-span-1 border border-t-2 relative aspect-square border-[#3C3C3C]
        
            ${index === 0 && " border-l-2 "}
            ${index === 11 && " border-r-2 "}
            
            `}
                onClick={() => onClickMonthFortune(item)}
              >
                {!loading && (
                  <div className="flex items-center justify-center w-full h-full">
                    {item}
                  </div>
                )}
                {isCurrentMonthFortune && (
                  <div
                    style={{
                      border: "2px solid red",
                      borderBottom: "none",
                    }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </th>
            );
          })}
        </tr>
        <tr className="grid grid-cols-12 col-span-1 text-xs sm:text-lg">
          {monthFortune.sky.map((item, index) => {
            const isCurrentMonthFortune =
              monthList[index] === currentMonthFortune;
            return (
              <td
                key={`month-forturne-sky-${index}`}
                className={`col-span-1 border aspect-square relative border-[#3C3C3C]
            ${index === 0 && " border-l-2 "}
            ${index === 11 && " border-r-2 "}
            `}
                style={{
                  backgroundColor: !loading ? item.color : "none",
                  color: item.color === "#727272" ? "white" : "black",
                  fontFamily: "KPACJKSC",
                }}
                onClick={() => onClickMonthFortune(monthList[index])}
              >
                {!loading && (
                  <div className="flex items-center justify-center w-full h-full ">
                    {item.chinese}
                  </div>
                )}
                {isCurrentMonthFortune && (
                  <div
                    style={{
                      border: "2px solid red",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </td>
            );
          })}
        </tr>
        <tr className="grid grid-cols-12 col-span-1 text-xs sm:text-lg">
          {monthFortune.ground.map((item, index) => {
            const isCurrentMonthFortune =
              monthList[index] === currentMonthFortune;
            return (
              <td
                key={`month-forturne-ground-${index}`}
                className={`overflow-hidden col-span-1 border border-b-2 relative aspect-square border-[#3C3C3C]
            ${index === 0 && " border-l-2 "}
            ${index === 11 && " border-r-2 "}
            `}
                style={{
                  borderBottomLeftRadius: index === 0 ? 6 : 0,
                  borderBottomRightRadius: index === 11 ? 6 : 0,
                  backgroundColor: !loading ? item.color : "none",
                  color: item.color === "#727272" ? "white" : "black",
                  fontFamily: "KPACJKSC",
                }}
                onClick={() => onClickMonthFortune(monthList[index])}
              >
                {!loading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    {item.chinese}
                  </div>
                ) : (
                  <ShimmerEffect />
                )}
                {isCurrentMonthFortune && (
                  <div
                    style={{
                      border: "2px solid red",
                      borderTop: "none",
                    }}
                    className="z-[10000] absolute top-0 left-0 w-full h-full "
                  />
                )}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}
