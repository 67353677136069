import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import CustomButton, { CustomButton2 } from "../../components/Button";
import { CustomInputV3 } from "../../components/Input";
import { AuthStateContext } from "../../contexts/AuthStateContext";
import {
  CURRENTROUTE,
  GENDERTYPE,
  SaveDataContext,
} from "../../contexts/SaveDataContext";
import { authAxios } from "../../framework/api";
import {
  getAccecssToken,
  getItem,
  loginTokenKey,
} from "../../utils/localStorage";
import {
  formatDate,
  formatDateWithDash,
  formatDatetoKoreanDate,
  formatTimeWithColon,
} from "../../utils/timeConvert";
import { ClientInfo } from "../../utils/types";
import InfoUPdateDialog from "../../components/InfoUPdateDialog";
import { createPortal } from "react-dom";

interface Root {
  data: ClientInfo[];
  total: number;
}
export default function AppMyPage() {
  const {
    universeUser,
    loadDefaultUser,
    loadMailUser,
    loadFemailUser,
    currentRoute,
    currentSelectGender,
  } = useContext(SaveDataContext);

  const navigate = useNavigate();

  const accessToken = getItem(loginTokenKey);
  const { authState } = useContext(AuthStateContext);
  const [clients, setClients] = useState<ClientInfo[]>([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage] = useState(1);
  const [, setToTal] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [dialog, setDialog] = useState(false);
  const [updateClient, setUpdateClient] = useState<ClientInfo>();
  const [todayVisit, setTodayVisit] = useState(0);
  const [visitCount, setVisitCount] = useState({
    todayVisitCount: 0,
    totalVisitCount: 0,
  });
  const [showVisit, setShowVisit] = useState(false);

  const onClickAddClient = () => {
    console.log(universeUser);
    authAxios
      .post(
        `/mobile/member`,
        {
          nickname: inputValue,
          gender: universeUser?.genderType,
          birthdayType: universeUser?.birthDayType,
          birthday: formatDateWithDash(universeUser?.birthday ?? ""),
          time: universeUser?.unknownTime
            ? "????"
            : formatTimeWithColon(universeUser?.time ?? ""),
          unknownTime: universeUser?.unknownTime,
        },
        {
          headers: {
            Authorization: `Bearer ${getAccecssToken(loginTokenKey)}`,
          },
        }
      )
      .then((resolve) => {
        window.location.reload();
      })
      .catch(function (error) {
        let errorMessage = error.message; // default to the generic error message
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        alert(errorMessage);
      });
  };

  const onClickLoadUser = (client: ClientInfo) => {
    if (currentRoute === CURRENTROUTE.DEFAULT) {
      loadDefaultUser({
        genderType: client.gender as GENDERTYPE,
        nickName: client.nickname,
        birthDayType: client.birthdayType,
        time: client.time,
        birthday: client.birthday,
        unknownTime: client.unknownTime,
      });
      navigate(`/app`);
    } else {
      if (currentSelectGender === "MALE") {
        loadMailUser({
          genderType: client.gender as GENDERTYPE,
          nickName: client.nickname,
          birthDayType: client.birthdayType,
          time: client.time,
          birthday: client.birthday,
          unknownTime: client.unknownTime,
        });
        navigate(`/app/compatible`);
      } else {
        loadFemailUser({
          genderType: client.gender as GENDERTYPE,
          nickName: client.nickname,
          birthDayType: client.birthdayType,
          time: client.time,
          birthday: client.birthday,
          unknownTime: client.unknownTime,
        });
        navigate(`/app/compatible`);
      }
    }
  };

  const onClickBackRoute = () => {
    if (currentRoute === CURRENTROUTE.DEFAULT) {
      navigate("/app");
    } else {
      navigate("/app/compatible");
    }
  };
  const onClose = () => {
    setDialog(false);
  };
  const onClickUpdateClient = (client: ClientInfo) => {
    setDialog(true);
    setUpdateClient(client);
  };
  const handleUpdateClent = (client: ClientInfo) => {
    authAxios
      .put(
        `/mobile/member/${client.id}`,
        {
          ...client,
        },
        {
          headers: {
            Authorization: `Bearer ${getAccecssToken(loginTokenKey)}`,
          },
        }
      )
      .then((resolve) => {
        setClients((prev) =>
          prev.map((item) => {
            if (item.id === client.id) {
              return {
                ...client,
              };
            } else {
              return item;
            }
          })
        );
      })
      .catch(function (error) {
        let errorMessage = error.message; // default to the generic error message

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        alert(errorMessage);
      })
      .finally(() => onClose());
  };

  const onClickDeleteClient = (clientId: number | string) => {
    authAxios
      .delete(`/mobile/member/${clientId}`, {
        headers: {
          Authorization: `Bearer ${getAccecssToken(loginTokenKey)}`,
        },
      })
      .then((resolve) => {
        setClients((prev) => {
          return [...prev.filter((item) => item.id !== clientId)];
        });
        alert("멤버 삭제 성공");
      })
      .catch(function (error) {
        let errorMessage = error.message; // default to the generic error message

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        alert(errorMessage);
      });
  };

  const onClickSearchClient = (name: string) => {
    authAxios
      .get<Root>(`/mobile/member?name=${name}&page=1&perPage=100`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccecssToken(loginTokenKey)}`,
        },
      })
      .then((resolve) => {
        setClients(resolve.data.data);
      })
      .catch(function (error) {
        let errorMessage = error.message; // default to the generic error message

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        alert(errorMessage);
      });
  };

  useEffect(() => {
    authAxios
      .get<Root>(`/mobile/member?page=${currentPage}&perPage=900`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccecssToken(loginTokenKey)}`,
        },
      })
      .then((resolve) => {
        //console.log(resolve.data);
        setClients(resolve.data.data);

        setToTal(resolve.data.total);
      })
      .catch(function (error) {
        let errorMessage = error.message; // default to the generic error message

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        alert("로그인이 필요한 페이지입니다. 로그인 후 다시 시도하십시오.");
      })
      .finally(() => {
        console.log("loadingFinish");
      });
    authAxios
      .get(`/mobile/visit`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccecssToken(loginTokenKey)}`,
        },
      })
      .then((resolve) => {
        if (resolve.data) {
          setShowVisit(true);
        } else {
          setShowVisit(false);
        }
        setVisitCount(resolve.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);
  if (!authState && accessToken === null) {
    return <Navigate to={"/app"} replace />;
  }

  return (
    <div className="flex flex-col items-center w-full h-full gap-2 px-2">
      {showVisit ? (
        <div className="w-[350px] flex justify-between  gap-2">
          <span>오늘 방문자수: {visitCount.todayVisitCount}</span>
          <span>전체 방문자수: {visitCount.totalVisitCount}</span>
        </div>
      ) : null}
      <div className="relative flex flex-col items-center w-[350px] h-full gap-2 px-2">
        <button
          className="absolute left-2 top-2"
          onClick={() => onClickBackRoute()}
        >
          뒤로가기
        </button>
        <button
          style={{
            fontSize: 24,
            fontFamily: "MapoGoldenPier",
            fontWeight: 800,
          }}
          onClick={() => navigate("/app")}
        >
          이규호 만세력
        </button>
        <div className="flex flex-col w-full gap-2">
          <CustomInputV3
            // maxLength={4}
            label="성명"
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.currentTarget.value.slice(0, 10))}
            className="w-full h-[54px]"
            placeholder="이름을 입력해 주세요"
          />
          <div className="flex w-full gap-2">
            <div className="w-3/4 h-[48px] rounded-md border border-[#5C5C5C] p-2 flex items-center">
              <span>
                {universeUser?.birthday
                  ? formatDate(universeUser.birthday)
                  : ""}
                {universeUser?.genderType
                  ? universeUser.birthDayType === "SOLAR"
                    ? " (양력)"
                    : universeUser.birthDayType === "LUNAR"
                    ? " (음력)"
                    : " (윤달)"
                  : ""}
                {universeUser?.genderType
                  ? universeUser.genderType === "MALE"
                    ? " 남자"
                    : " 여자"
                  : ""}
              </span>
            </div>
            <CustomButton2
              onClick={onClickAddClient}
              primaryType={true}
              disabled={universeUser === null || inputValue.trim().length === 0}
              text="저장"
              className={
                "w-1/4 h-[48px] " +
                `${
                  universeUser === null || inputValue.trim().length === 0
                    ? "bg-slate-300"
                    : " bg-[#9D7FDE]"
                }`
              }
            />
          </div>
        </div>
        <hr className="w-full h-1 mb-3 bg-slate-200" />
        <div className="flex justify-start w-full mb-3 font-semibold">
          검색하기
        </div>
        <div className="flex w-full gap-2 ">
          <div className="w-3/4">
            <CustomInputV3
              value={searchText}
              type={"input"}
              label={"성명"}
              placeholder="이름을 입력해 주세요"
              className="w-full h-[54px]"
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
          </div>
          <CustomButton
            onClick={() => onClickSearchClient(searchText)}
            type="submit"
            primaryType={true}
            // disabled={hourMin.length !== 4 || yearMonthDay.length !== 8}
            text="검색"
            className="w-1/4  h-[54px]  font-extrabold "
          />
        </div>
        <hr className="w-full h-1 mb-6 bg-slate-200" />
        <div className="w-full">
          {clients.map((client, index) => (
            <span key={index}>
              <div
                className="flex justify-between w-full gap-2"
                key={`client-inf-${index}`}
              >
                <div className="flex flex-col items-start w-3/4">
                  <span className="flex justify-between w-full">
                    <span
                      className="font-bold"
                      style={{
                        fontSize: client.nickname.length > 9 ? 13 : 16,
                      }}
                    >
                      {client.nickname}
                    </span>
                    <span className="flex gap-2">
                      <button
                        className="rounded-full w-[49px] h-[27px] border border-slate-400 text-slate-400 text-[13px]"
                        onClick={() => onClickDeleteClient(client.id)}
                      >
                        삭제
                      </button>
                      <button
                        className="rounded-full w-[49px] h-[27px] border border-[#3C8AFF] text-[#3146FF] text-[13px]"
                        onClick={() => onClickUpdateClient(client)}
                      >
                        수정
                      </button>
                    </span>
                  </span>
                  <span>
                    {formatDatetoKoreanDate(client.birthday)}
                    {client.birthdayType === "SOLAR"
                      ? " (양력)"
                      : client.birthdayType === "LUNAR"
                      ? " (음력)"
                      : " (윤달)"}
                    {client.gender === "MALE" ? " 남자" : " 여자"}
                  </span>
                </div>
                <CustomButton
                  onClick={() => onClickLoadUser(client)}
                  primaryType={false}
                  text="불러오기"
                  className="w-1/4 h-[48px] font-extrabold"
                />
              </div>
              <hr className="w-full h-[1px] bg-slate-200 my-4" />
            </span>
          ))}
        </div>
      </div>
      {dialog &&
        createPortal(
          <InfoUPdateDialog
            updateClient={updateClient}
            onClose={onClose}
            handleUpdateClent={handleUpdateClent}
          />,
          document.body
        )}
    </div>
  );
}
