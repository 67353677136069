import { createContext, useState } from "react";
import { setInitUser } from "../utils/defaultUserSet";

export interface SaveUser {
  genderType: GENDERTYPE;
  nickName: string | null;
  birthDayType: string;
  time: string;
  birthday: string;
  unknownTime: boolean;
}
type SaveDataContextType = {
  defaultUser: SaveUser;
  mailUser: SaveUser;
  femailUser: SaveUser;
  universeUser: SaveUser | null;
  currentRoute: CURRENTROUTE;
  currentSelectGender: GENDERTYPE;
  loadDefaultUser: (value: SaveUser) => void;
  loadMailUser: (value: SaveUser) => void;
  loadFemailUser: (value: SaveUser) => void;
  saveDefaultUser: (value: SaveUser) => void;
  saveMailUser: (value: SaveUser) => void;
  saveFemailUser: (value: SaveUser) => void;
  reset: () => void;
};
export type GENDERTYPE = "MALE" | "FEMALE";

export enum CURRENTROUTE {
  DEFAULT,
  COMPATIBLE,
}

export const SaveDataContext = createContext<SaveDataContextType>({
  defaultUser: setInitUser("MALE"),
  mailUser: setInitUser("MALE"),
  femailUser: setInitUser("FEMALE"),
  universeUser: null,
  currentRoute: CURRENTROUTE.DEFAULT,
  currentSelectGender: "MALE",
  loadDefaultUser: () => {},
  loadMailUser: () => {},
  loadFemailUser: () => {},
  saveDefaultUser: () => {},
  saveMailUser: () => {},
  saveFemailUser: () => {},
  reset: () => {},
});
export const SaveDataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [defaultUser, setDefaultUser] = useState<SaveUser>(setInitUser("MALE"));
  const [mailUser, setMailUser] = useState<SaveUser>(setInitUser("MALE"));
  const [femailUser, setFemailUser] = useState<SaveUser>(setInitUser("FEMALE"));
  const [universeUser, setUniverseUser] = useState<SaveUser | null>(null);

  const [currentRoute, setCurrentRoute] = useState<CURRENTROUTE>(
    CURRENTROUTE.DEFAULT
  );
  const [currentSelectGender, setCurrentSelectGender] =
    useState<GENDERTYPE>("MALE");

  /** 초기화  */
  const reset = () => {
    setDefaultUser(setInitUser("MALE"));
    setMailUser(setInitUser("MALE"));
    setFemailUser(setInitUser("FEMALE"));
    setUniverseUser(null);
    setCurrentRoute(CURRENTROUTE.DEFAULT);
    setCurrentSelectGender("MALE");
  };

  /** 마이페이지 -> 사주페이지로 유저 정보 불러오기 */
  const loadDefaultUser = (value: SaveUser) => {
    setDefaultUser(value);
  };

  /** 마이페이지 -> 궁합페이지 남자 정보 불러오기 */
  const loadMailUser = (value: SaveUser) => {
    setMailUser(value);
  };

  /** 마이페이지 -> 궁합페이지 여자 정보 불러오기 */
  const loadFemailUser = (value: SaveUser) => {
    setFemailUser(value);
  };

  /** 사주페이지 -> 마이페이지 */
  const saveDefaultUser = (value: SaveUser) => {
    setUniverseUser(value);
    setCurrentRoute(CURRENTROUTE.DEFAULT);
  };

  /** 궁합페이지 -> 마이페이지 남자 */
  const saveMailUser = (value: SaveUser) => {
    setUniverseUser(value);
    setCurrentRoute(CURRENTROUTE.COMPATIBLE);
    setCurrentSelectGender("MALE");
  };

  /** 궁합페이지 -> 마이페이지 남자 */
  const saveFemailUser = (value: SaveUser) => {
    setUniverseUser(value);
    setCurrentRoute(CURRENTROUTE.COMPATIBLE);
    setCurrentSelectGender("FEMALE");
  };

  const value = {
    defaultUser,
    mailUser,
    femailUser,
    universeUser,
    currentRoute,
    currentSelectGender,
    setDefaultUser,
    loadDefaultUser,
    loadMailUser,
    loadFemailUser,
    saveDefaultUser,
    saveMailUser,
    saveFemailUser,
    reset,
  };

  return (
    <SaveDataContext.Provider value={value}>
      {children}
    </SaveDataContext.Provider>
  );
};
