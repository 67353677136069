import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthStateContextProvider } from "./contexts/AuthStateContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SaveDataContextProvider } from "./contexts/SaveDataContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SaveDataContextProvider>
      <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      >
        <AuthStateContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthStateContextProvider>
      </GoogleOAuthProvider>
    </SaveDataContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
