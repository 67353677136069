import { BigFortune, HundredList } from "./types";

export function createSmallFortuneTableArray(
  hundredList: HundredList,
  bigFortuneProps: BigFortune
): HundredList[] {
  /**
   * 1. 필요한 값이 무엇인가.
   * -> 해당 세운이 가지는 대운의 인덱스값 & 세운의 해당 년도
   * 2. 보여줄 값이 무엇인가.
   * -> 해당 세운의 년도, 천간, 지간, 나이
   * 3. 얼만큼만 보여주어야 하는가.
   * -> 100개만 보여준다. 단, 대운은 10개이고 해당 대운마다 10개의 세운이 필요하다.
   * 4. 어떻게 분리할 것인가.
   * -> 시작하는 대운을 기준으로
   */
  const chunkedArray: HundredList[] = [];
  const { year, sky, ground, age } = hundredList;

  /** 분기할 인덱스 위치를 찾는다. */
  const bigFortuneFirstYear = bigFortuneProps.year.at(-2);
  const findSeperateIndex = age.findIndex(
    (item) => item === bigFortuneFirstYear
  );

  const maxElements = 10;
  const startIndex =
    findSeperateIndex > maxElements ? findSeperateIndex - maxElements : 0;

  const prevHundredList: HundredList = {
    year: year.slice(startIndex, findSeperateIndex).reverse(),
    sky: sky.slice(startIndex, findSeperateIndex).reverse(),
    ground: ground.slice(startIndex, findSeperateIndex).reverse(),
    age: age.slice(startIndex, findSeperateIndex).reverse(),
    // lessAge: age.slice(startIndex, findSeperateIndex).reverse(),
  };

  const nextHundredList: HundredList = {
    year: year.slice(findSeperateIndex),
    sky: sky.slice(findSeperateIndex),
    ground: ground.slice(findSeperateIndex),
    age: age.slice(findSeperateIndex),
    // lessAge: age.slice(findSeperateIndex),
  };
  chunkedArray.push(prevHundredList);

  let nextIndex = 0;
  while (nextIndex < nextHundredList.age.length) {
    const chunk = {
      year: fillWithUndefined(
        nextHundredList.year.slice(nextIndex, nextIndex + 10)
      ).reverse(),
      sky: fillWithUndefined(
        nextHundredList.sky.slice(nextIndex, nextIndex + 10)
      ).reverse(),
      ground: fillWithUndefined(
        nextHundredList.ground.slice(nextIndex, nextIndex + 10)
      ).reverse(),
      age: fillWithUndefined(
        nextHundredList.age.slice(nextIndex, nextIndex + 10)
      ).reverse(),
      // lessAge: fillWithUndefined(
      //   nextHundredList.lessAge.slice(nextIndex, nextIndex + 10)
      // ).reverse(),
    };
    chunkedArray.push(chunk);

    nextIndex += 10;
  }

  return chunkedArray;
}
function fillWithUndefined(arr: any[]) {
  while (arr.length < 10) {
    arr.push(undefined);
  }
  return arr;
}
