interface CustomSelectProps extends React.ComponentPropsWithoutRef<"select"> {
  options: OptionType[];
  className: string;
}

interface OptionType {
  value: any;
  key: string;
}

export default function CustomSelect({
  options,
  title,
  className,
  ...props
}: CustomSelectProps) {
  return (
    <div
      className={
        "relative border-2 border-slate-400 px-2 py-0 flex " + className
      }
      style={{ borderRadius: 6, outlineColor: "#9D7FDE" }}
    >
      <span
        className="absolute bg-white leading-none -top-1 sm:-top-[0.5vw] left-1 text-slate-400 text-xs"
        // style={{ fontSize: 12 }}
      >
        {title}
      </span>
      <select className="w-full h-full " style={{ outline: "none" }} {...props}>
        {options.map((item) => (
          <option key={`select-${item.key}`} value={item.value}>
            {item.key}
          </option>
        ))}
      </select>
    </div>
  );
}

/** 시 선택 */
export function CustomSelectChoose({
  options,
  title,
  className,
  ...props
}: CustomSelectProps) {
  return (
    <div
      className={
        "relative border-2 border-slate-400 px-2 py-0 flex " + className
      }
      style={{ borderRadius: 6, outlineColor: "#9D7FDE" }}
    >
      <span
        className="absolute bg-white leading-none -top-1 sm:-top-[0.5vw] left-1 text-slate-400 text-xs"
        // style={{ fontSize: 12 }}
      >
        {title}
      </span>
      <select className="w-full h-full" style={{ outline: "none" }} {...props}>
        {options.map((item) => (
          <option key={`select-${item.key}`} value={item.value}>
            {item.key}
          </option>
        ))}
      </select>
    </div>
  );
}

/** 시 선택 */
export function CustomSelectChooseV2({
  options,
  title,
  className,
  ...props
}: CustomSelectProps) {
  return (
    <div
      className={
        "relative border-2 border-slate-400 px-2 py-0 flex" + className
      }
      style={{ borderRadius: 6, outlineColor: "#9D7FDE" }}
    >
      <span
        className="absolute bg-white leading-none -top-1 sm:-top-[0.5vw] left-1 text-slate-400 text-xs"
        // style={{ fontSize: 12 }}
      >
        {title}
      </span>
      <select className="w-full h-full " style={{ outline: "none" }} {...props}>
        {options.map((item) => (
          <option key={`select-${item.key}`} value={item.value}>
            {item.key}
          </option>
        ))}
      </select>
    </div>
  );
}

/** 궁합페이지 선택창 */
export function CustomSelectV2({
  options,
  title,
  className,
  ...props
}: CustomSelectProps) {
  return (
    <div
      className={
        "relative rounded-[4px] sm:rounded-md border-[0.1vw] sm:border-[1.8px] border-slate-400 px-[0.5vw] flex w-full " +
        className
      }
      style={{ outlineColor: "#9D7FDE" }}
    >
      <span className="absolute bg-white leading-none -top-1 sm:-top-[0.5vw] left-1 text-slate-400 text-[7px] sm:text-xs">
        {title}
      </span>
      <select
        className="w-full h-full text-xs sm:text-base "
        style={{ outline: "none" }}
        {...props}
      >
        {options.map((item) => (
          <option key={`select-${item.key}`} value={item.value}>
            {item.key}
          </option>
        ))}
      </select>
    </div>
  );
}
