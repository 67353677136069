export function setItem(name: string, value: any) {
  localStorage.setItem(name, JSON.stringify(value));
}
export function getItem(name: string) {
  const localData = localStorage.getItem(name);
  if (localData) return JSON.parse(localData);
  return null;
}
export function removeItem(name: string) {
  localStorage.removeItem(name);
  return null;
}
export function getAccecssToken(name: string) {
  const localData = localStorage.getItem(name);
  if (localData) {
    const token = JSON.parse(localData);
    return token.accessToken as string;
  }
  return "";
}

export function getRefreshToken(name: string) {
  const localData = localStorage.getItem(name);
  if (localData) {
    const token = JSON.parse(localData);
    return token.refreshToken as string;
  }
  return "";
}
export function getToken(name: string) {
  const localData = localStorage.getItem(name);
  if (localData) {
    const token = JSON.parse(localData);
    return token;
  }
  return null;
}

export interface RESPONSETYPE {
  id: number;
  accessToken: string;
  refreshToken: string;
}

export const loginTokenKey = "fortune.LOCALKEY.OWNER";
export const currentClubID = "fortune.LOCALKEY.clubID";
export const currentGrade = "fortune.LOCALKEY.grade";
export const currentMyID = "fortune.LOCALKEY.myID";
export const joinClubLength = "fortune.LOCALKEY.clubLength";
export const ExpiredAt = "fortune.expiresAt";
