import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthStateContext } from "../contexts/AuthStateContext";
import { authAxios } from "../framework/api";
import { loginTokenKey, setItem } from "../utils/localStorage";

export default function KakaoLoginPage() {
  const { handleChangeAuthState } = useContext(AuthStateContext);
  const navigate = useNavigate();
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const code = new URL(window.location.href).searchParams.get("code");

  const Rest_api_key = process.env.REACT_APP_KAKAO_KEY;
  const redirect_uri = `${process.env.REACT_APP_ROOT_URL}/login/kakao/redirect`;

  useEffect(() => {
    const kakaoLogin = async () => {
      try {
        const kakaoResponse = await axios.post(
          `https://kauth.kakao.com/oauth/token`,
          {
            grant_type: "authorization_code",
            client_id: Rest_api_key,
            redirect_uri: redirect_uri,
            code: code,
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        );
        // //console.log("kakaoResponse:: ", kakaoResponse);

        const loginResult = await axios.get(
          "https://kapi.kakao.com/v2/user/me",
          {
            headers: {
              Authorization: `Bearer ${kakaoResponse.data.access_token}`,
            },
          }
        );
        // //console.log("loginResult:: ", loginResult);
        if (loginResult.status === 200 || 201) {
          //카카오 로그인 성공하면 id 값으로 우리 서버에 로그인
          const socialLogin = await authAxios.post(`/mobile/auth/social`, {
            type: "kakao",
            socialId: loginResult.data.id,
            email: loginResult.data.kakao_account.email,
          });
          // //console.log("socialLogin.status:: ", socialLogin.data);

          if (socialLogin.status === 201 || socialLogin.status === 200) {
            setItem(loginTokenKey, socialLogin.data);
            handleChangeAuthState(true);
            setShouldNavigate(true); // Set the flag to trigger navigation
          } else {
            navigate("/login", { replace: true });
          }
        }

        // //console.log(loginResult);
      } catch (error) {
        console.error("An error occurred during Naver login:", error);
        navigate("/login", { replace: true });
      }
    };
    kakaoLogin();
  });
  useEffect(() => {
    if (shouldNavigate) {
      navigate("/", { replace: true });
    }
  }, [shouldNavigate, navigate]);

  // if (authState) {
  //   return <Navigate to={"/"} replace />;
  // }
  return <div>kakao login redirect page</div>;
}
