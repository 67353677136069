import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../components/Button";
import { ClientInfoV2 } from "../components/ClientInfo";
import { CustomInputV2 } from "../components/Input";
import Menu from "../components/Menu";
import { CustomSelectV2 } from "../components/Selector";
import { BigFortuneTableV2 } from "../components/Table/BigFortuneTable";
import { MonthFortuneTableV2 } from "../components/Table/MonthFortuneTable";
import { SajuTableV3 } from "../components/Table/SajuTable";
import { SmallFortuneTableV2 } from "../components/Table/SmallFortuneTable";
import { AuthStateContext } from "../contexts/AuthStateContext";
import { GENDERTYPE, SaveDataContext } from "../contexts/SaveDataContext";
import { getSaju } from "../framework/api";
import { createSmallFortuneTableArray } from "../utils/createSmallFortuneTableArray";
import { defaultSet, timeOptions } from "../utils/data";
import {
  convertDateString,
  convertTimeString,
  getCurrentMonth,
  getCurrentYear,
  getHMString,
  getYMDString,
} from "../utils/timeConvert";
import { HundredList, Root } from "../utils/types";
import urlNav from "../utils/urlNav";

export default function CompatiblePage() {
  const {
    mailUser,
    femailUser,
    loadMailUser,
    loadFemailUser,
    saveMailUser,
    saveFemailUser,
    reset,
  } = useContext(SaveDataContext);
  const nickName = mailUser.nickName;
  const genderType = mailUser.genderType;
  const birthDayType = mailUser.birthDayType;
  const time = mailUser.time;
  const birthday = mailUser.birthday;
  const unknownTime = mailUser.unknownTime;

  const _nickName = femailUser.nickName;
  const _genderType = femailUser.genderType;
  const _birthDayType = femailUser.birthDayType;
  const _time = femailUser.time;
  const _birthday = femailUser.birthday;
  const _unknownTime = femailUser.unknownTime;

  const navigate = useNavigate();
  const printRef = useRef<HTMLDivElement>(null);
  const [firstResult, setFirstResult] = useState<Root>(defaultSet);
  const [secondResult, setSecondResult] = useState<Root>(defaultSet);
  const [hundredList, setHundredList] = useState<HundredList[]>(
    createSmallFortuneTableArray(defaultSet.hundredList, defaultSet.bigFortune)
  );
  const [_hundredList, _setHundredList] = useState<HundredList[]>(
    createSmallFortuneTableArray(defaultSet.hundredList, defaultSet.bigFortune)
  );

  const [loading, setLoading] = useState(true);
  const [_loading, _setLoading] = useState(true);

  const [yearMonthDay, setYearMonthDay] = useState(
    convertDateString(birthday) ?? getYMDString(new Date())
  );
  const [_yearMonthDay, _setYearMonthDay] = useState(
    convertDateString(_birthday) ?? getYMDString(new Date())
  );
  const [hourMin, setHourMin] = useState(
    convertTimeString(time) ?? getHMString(new Date())
  );
  const [_hourMin, _setHourMin] = useState(
    convertTimeString(_time) ?? getHMString(new Date())
  );
  const [birthType, setBirthType] = useState(birthDayType);
  const [_birthType, _setBirthType] = useState(_birthDayType);
  const [gender, setGender] = useState(genderType);
  const [_gender, _setGender] = useState(_genderType);

  const [currentBigFortune, setCurrentBigFortune] = useState<number | null>(
    null
  );
  const [currentSmallFortune, setCurrentSmallFortune] = useState<number | null>(
    null
  );
  const [currentMonthFortune, setCurrentMonthFortune] = useState<number | null>(
    null
  );

  const [_currentBigFortune, _setCurrentBigFortune] = useState<number | null>(
    null
  );
  const [_currentSmallFortune, _setCurrentSmallFortune] = useState<
    number | null
  >(null);
  const [_currentMonthFortune, _setCurrentMonthFortune] = useState<
    number | null
  >(null);

  const [inputDisabled, setInputDisabled] = useState(false);
  const [currentTimeOption, setCurrentTimeOption] = useState("default");

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;

    if (value === "default") {
      setInputDisabled(false);
      setCurrentTimeOption(value);
    } else {
      setInputDisabled(true);
      setCurrentTimeOption(value);
      setHourMin(value);
    }
  };

  const [_inputDisabled, _setInputDisabled] = useState(false);
  const [_currentTimeOption, _setCurrentTimeOption] = useState("default");

  const _handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;

    if (value === "default") {
      _setInputDisabled(false);
      _setCurrentTimeOption(value);
    } else {
      _setInputDisabled(true);
      _setCurrentTimeOption(value);
      _setHourMin(value);
    }
  };

  const { authState } = useContext(AuthStateContext);

  const onClickSaveButton = () => {
    // 로그인한 회원이라면
    if (authState) {
      saveMailUser({
        nickName: null,
        birthday: yearMonthDay,
        time: hourMin,
        birthDayType: birthType,
        genderType: gender,
        unknownTime: hourMin === "????" ? true : false,
      });
      navigate("/MyPage");
    } else {
      // 로그인하지 않은 회원이라면 로그인 페이지로 이동.
      navigate("/login");
    }
  };
  const _onClickSaveButton = () => {
    // 로그인한 회원이라면
    if (authState) {
      saveFemailUser({
        nickName: null,
        birthday: _yearMonthDay,
        time: _hourMin,
        birthDayType: _birthType,
        genderType: _gender,
        unknownTime: _hourMin === "????" ? true : false,
      });
      navigate("/MyPage");
    } else {
      // 로그인하지 않은 회원이라면 로그인 페이지로 이동.
      navigate("/login");
    }
  };

  const onClickSeeFortune = () => {
    if (
      hourMin.length !== 4 ||
      yearMonthDay.length !== 8 ||
      _hourMin.length !== 4 ||
      _yearMonthDay.length !== 8
    ) {
      alert("입력값을 확인하세요.");
      return;
    }
    setCurrentBigFortune(null);
    setCurrentSmallFortune(null);

    _setCurrentBigFortune(null);
    _setCurrentSmallFortune(null);

    getSaju
      .get<Root>(
        urlNav({
          url: `/mobile/manse`,
          gender: gender,
          birthType: birthType,
          yearMonthDay: yearMonthDay,
          hourMin: hourMin,
          unknownTime: hourMin === "????" ? true : false,
          currentSmallFortune: getCurrentYear(),
        })
      )
      .then((resolve) => {
        const { saju, hundredList, bigFortune } = resolve.data;
        setCurrentBigFortune(saju.fortune.big);
        setCurrentSmallFortune(saju.fortune.small);
        setCurrentMonthFortune(getCurrentMonth());
        setFirstResult(resolve.data);
        setHundredList(createSmallFortuneTableArray(hundredList, bigFortune));
        loadMailUser({
          nickName: null,
          birthday: yearMonthDay,
          time: hourMin,
          birthDayType: birthType,
          genderType: gender,
          unknownTime: hourMin === "????" ? true : false,
        });
      })
      .catch(function (error) {
        let errorMessage = error.message; // default to the generic error message

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        alert(errorMessage ?? "유효하지 않은 값입니다.");
      })
      .finally(() => {
        setLoading(false);
      });
    getSaju
      .get<Root>(
        urlNav({
          url: `/mobile/manse`,
          gender: _gender,
          birthType: _birthType,
          yearMonthDay: _yearMonthDay,
          hourMin: _hourMin,
          unknownTime: _hourMin === "????" ? true : false,
          currentSmallFortune: getCurrentYear(),
        })
      )
      .then((resolve) => {
        const { saju, hundredList, bigFortune } = resolve.data;
        _setCurrentBigFortune(saju.fortune.big);
        _setCurrentSmallFortune(saju.fortune.small);
        _setCurrentMonthFortune(getCurrentMonth());
        setSecondResult(resolve.data);
        _setHundredList(createSmallFortuneTableArray(hundredList, bigFortune));
        loadFemailUser({
          nickName: null,
          birthday: _yearMonthDay,
          time: _hourMin,
          birthDayType: _birthType,
          genderType: _gender,
          unknownTime: _hourMin === "????" ? true : false,
        });
      })
      .catch(function (error) {
        let errorMessage = error.message; // default to the generic error message

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        alert(errorMessage ?? "유효하지 않은 값입니다.");
      })
      .finally(() => {
        _setLoading(false);
      });
  };

  const onClickBigFortune = (value: number) => {
    setCurrentBigFortune(value);
    setCurrentSmallFortune(null);
    setCurrentMonthFortune(null);
  };

  const onClickSmallFortune = (value: number) => {
    if (currentBigFortune) {
      setCurrentSmallFortune(value);
      setCurrentMonthFortune(null);
      getSaju
        .get<Root>(
          urlNav({
            url: `/mobile/manse`,
            gender: gender,
            birthType: birthType,
            yearMonthDay: yearMonthDay,
            hourMin: hourMin,
            unknownTime: unknownTime,
            currentBigFortune: currentBigFortune,
            currentSmallFortune: value,
          })
        )
        .then((resolve) => {
          const { monthFortune } = resolve.data;
          setFirstResult((prev) => {
            return { ...prev, monthFortune };
          });
        })
        .catch(function (error) {
          let errorMessage = error.message; // default to the generic error message

          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
          }

          alert(errorMessage ?? "유효하지 않은 값입니다.");
        });
    }
  };

  const _onClickBigFortune = (value: number) => {
    _setCurrentBigFortune(value);
    _setCurrentSmallFortune(null);
    _setCurrentMonthFortune(null);
  };
  const _onClickSmallFortune = (value: number) => {
    if (_currentBigFortune) {
      _setCurrentSmallFortune(value);
      _setCurrentMonthFortune(null);
      getSaju
        .get<Root>(
          urlNav({
            url: `/mobile/manse`,
            gender: _gender,
            birthType: _birthType,
            yearMonthDay: _yearMonthDay,
            hourMin: _hourMin,
            unknownTime: _unknownTime,
            currentBigFortune: _currentBigFortune,
            currentSmallFortune: value,
          })
        )
        .then((resolve) => {
          const { monthFortune } = resolve.data;
          setSecondResult((prev) => {
            return { ...prev, monthFortune };
          });
        })
        .catch(function (error) {
          let errorMessage = error.message; // default to the generic error message

          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
          }

          alert(errorMessage ?? "유효하지 않은 값입니다.");
        })
        .finally(() => {});
    }
  };

  useEffect(() => {
    // First API call
    const firstApiCall = getSaju
      .get<Root>(
        urlNav({
          url: `/mobile/manse`,
          gender: gender,
          birthType: birthType,
          yearMonthDay: yearMonthDay,
          hourMin: hourMin,
          unknownTime: unknownTime,
          currentSmallFortune: getCurrentYear(),
          currentMonthFortune: getCurrentMonth(),
        })
      )
      .then((resolve) => {
        const { saju, hundredList, bigFortune } = resolve.data;
        setCurrentBigFortune(saju.fortune.big);
        setCurrentSmallFortune(saju.fortune.small);
        setCurrentMonthFortune(getCurrentMonth());
        setFirstResult(resolve.data);
        setHundredList(createSmallFortuneTableArray(hundredList, bigFortune));
      })
      .catch(function (error) {
        let errorMessage = error.message;

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        alert(errorMessage ?? "유효하지 않은 값입니다.");
      });

    // Second API call
    const secondApiCall = getSaju
      .get<Root>(
        urlNav({
          url: `/mobile/manse`,
          gender: _gender,
          birthType: _birthType,
          yearMonthDay: _yearMonthDay,
          hourMin: _hourMin,
          unknownTime: _unknownTime,
          currentSmallFortune: getCurrentYear(),
          currentMonthFortune: getCurrentMonth(),
        })
      )
      .then((resolve) => {
        const { saju, hundredList, bigFortune } = resolve.data;
        _setCurrentBigFortune(saju.fortune.big);
        _setCurrentSmallFortune(saju.fortune.small);
        _setCurrentMonthFortune(getCurrentMonth());
        setSecondResult(resolve.data);
        _setHundredList(createSmallFortuneTableArray(hundredList, bigFortune));
      })
      .catch(function (error) {
        let errorMessage = error.message;

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        alert(errorMessage ?? "유효하지 않은 값입니다.");
      });

    // Use Promise.all to wait for both API calls to complete
    Promise.all([firstApiCall, secondApiCall]).then(() => {
      console.log("loadingFinish");
      setLoading(false);
      _setLoading(false);
    });
  }, []);

  return (
    <div className="flex justify-center w-full min-h-full">
      {/* 가로모드 좌측 빈여백 */}
      {/* <div className="sm:w-[27%] sm:visible " /> */}
      {/* 메인 내용 */}

      <div className="relative sm:w-full lg:w-[70%] max-w-[716px] max-lg:px-2 mb-3">
        <div className="absolute right-0 top-4">
          <Menu printRef={printRef} />
        </div>
        <div className="w-full" ref={printRef}>
          {/* 이규호 만세력 header */}
          <div className=" flex w-full  py-[11px]  justify-center sm:justify-start ">
            <button
              style={{
                fontSize: "max(32px,1vw)",

                fontFamily: "MapoGoldenPier",
                fontWeight: 800,
              }}
              onClick={() => {
                reset();
                navigate("/");
              }}
            >
              이규호 만세력
            </button>
          </div>

          {/* 사주 정보 입력창  */}
          <div className="grid w-full grid-cols-7 gap-1 ">
            {/* 남성 입력창 */}
            <div className="grid grid-cols-3 col-span-3 gap-x-1 gap-y-2">
              {/* 년월일 시입력 시선택 */}
              <div className="grid grid-cols-3 col-span-3 gap-1">
                <div className="grid grid-cols-3 col-span-2 gap-1">
                  <CustomInputV2
                    maxLength={8}
                    type={"input"}
                    name={"yearMonthDay"}
                    label={"년월일"}
                    placeholder="20230704"
                    className="col-span-2 h-[46px]"
                    value={yearMonthDay}
                    onChange={(e) => {
                      const newValue = e.currentTarget.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      setYearMonthDay(newValue);
                    }}
                  />
                  <CustomInputV2
                    maxLength={4}
                    type={"text"}
                    name={"hourMin"}
                    label={"시 입력"}
                    disabled={inputDisabled}
                    placeholder="1522"
                    className="col-span-1 h-[46px]"
                    value={hourMin}
                    onChange={(e) => {
                      const newValue = e.currentTarget.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      setHourMin(newValue);
                    }}
                  />
                </div>

                <CustomSelectV2
                  title="시 선택"
                  options={timeOptions}
                  value={currentTimeOption}
                  className="col-span-1 h-[46px]"
                  // onChange={(e) => setHourMin(e.currentTarget.value)}
                  onChange={handleChange}
                />
              </div>
              {/* 양,음,윤 성별 저장버튼 */}
              <div className="grid grid-cols-3 col-span-3 gap-1">
                <CustomSelectV2
                  title="양,음,윤"
                  options={[
                    { key: "양력", value: "SOLAR" },
                    { key: "음력", value: "LUNAR" },
                    { key: "윤달", value: "LEAP" },
                  ]}
                  value={birthType}
                  onChange={(e) => {
                    setBirthType(e.currentTarget.value);
                  }}
                  className="col-span-1 h-[46px]"
                />
                <CustomSelectV2
                  title="성별"
                  options={[
                    { key: "남", value: "MALE" },
                    { key: "여", value: "FEMALE" },
                  ]}
                  value={gender}
                  onChange={(e) => {
                    setGender(e.currentTarget.value as GENDERTYPE);
                  }}
                  className="col-span-1 h-[46px]"
                />

                <CustomButton
                  onClick={onClickSaveButton}
                  primaryType={false}
                  disabled={hourMin.length !== 4 || yearMonthDay.length !== 8}
                  text="저장"
                  className="col-span-1 font-extrabold h-[46px]"
                />
              </div>
            </div>
            {/* <div className="min-h-full w-[1px] bg-slate-400" /> */}
            <CustomButton
              onClick={onClickSeeFortune}
              type="submit"
              primaryType={true}
              // disabled={
              //   hourMin.length !== 4 ||
              //   yearMonthDay.length !== 8 ||
              //   _hourMin.length !== 4 ||
              //   _yearMonthDay.length !== 8
              // }
              text="사주보기"
              className="h-full col-span-1 font-extrabold "
            />
            {/* 여성 입력창 */}
            <div className="grid grid-cols-3 col-span-3 gap-x-1 gap-y-2">
              {/* 년월일 시입력 시선택 */}
              <div className="grid grid-cols-3 col-span-3 gap-1">
                <div className="grid grid-cols-3 col-span-2 gap-1">
                  <CustomInputV2
                    maxLength={8}
                    type={"input"}
                    name={"yearMonthDay"}
                    label={"년월일"}
                    placeholder="20230704"
                    className="col-span-2 h-[46px]"
                    value={_yearMonthDay}
                    onChange={(e) => {
                      const newValue = e.currentTarget.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      _setYearMonthDay(newValue);
                    }}
                  />
                  <CustomInputV2
                    maxLength={4}
                    type={"text"}
                    name={"hourMin"}
                    label={"시 입력"}
                    disabled={_inputDisabled}
                    placeholder="1522"
                    className="col-span-1 h-[46px]"
                    value={_hourMin}
                    onChange={(e) => {
                      const newValue = e.currentTarget.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      _setHourMin(newValue);
                    }}
                  />
                </div>

                <CustomSelectV2
                  title="시 선택"
                  options={timeOptions}
                  value={_currentTimeOption}
                  className="col-span-1 h-[46px]"
                  onChange={_handleChange}
                />
              </div>
              {/* 양,음,윤 성별 저장버튼 */}
              <div className="grid grid-cols-3 col-span-3 gap-1">
                <CustomSelectV2
                  title="양,음,윤"
                  options={[
                    { key: "양력", value: "SOLAR" },
                    { key: "음력", value: "LUNAR" },
                    { key: "윤달", value: "LEAP" },
                  ]}
                  value={_birthType}
                  onChange={(e) => {
                    _setBirthType(e.currentTarget.value);
                  }}
                  className="col-span-1 h-[46px]"
                />
                <CustomSelectV2
                  title="성별"
                  options={[
                    { key: "남", value: "MALE" },
                    { key: "여", value: "FEMALE" },
                  ]}
                  value={_gender}
                  onChange={(e) => {
                    _setGender(e.currentTarget.value as GENDERTYPE);
                  }}
                  className="col-span-1 h-[46px]"
                />

                <CustomButton
                  onClick={_onClickSaveButton}
                  primaryType={false}
                  disabled={_hourMin.length !== 4 || _yearMonthDay.length !== 8}
                  text="저장"
                  className="col-span-1 h-[46px] font-extrabold"
                />
              </div>
            </div>
            {/* 사주보기 버튼 */}
          </div>
          {/* 가로 선 */}
          <div className="w-full h-[1px] bg-[#DCDCDC] my-2" />
          {/* 사주 테이블, 대운 테이블, 세운 테이블, 월운 테이블, 달력 테이블 */}
          <div className="flex w-full gap-3">
            {/* 남성 사주 정보 */}
            <div className="flex flex-col items-center w-1/2">
              <ClientInfoV2
                loading={loading}
                nickName={nickName}
                genderType={genderType}
                birthday={birthday}
                birthDayType={birthDayType}
                seasonName={firstResult.seasonName}
                summmerTime={firstResult.summmerTime}
                unknownTime={firstResult.unknownTime}
                solarDate={firstResult.solarBirthDay}
                lunaDate={firstResult.lunarBirthDay}
                age={firstResult.currentAge}
              />

              {/* saju table */}
              <SajuTableV3
                saju={firstResult.saju}
                loading={loading}
                unknownTime={firstResult.unknownTime}
              />

              <div className="w-full h-[2px] bg-[#3C3C3C] my-3 md:bg-transparent md:my-1" />

              {/* bigfortune */}
              <BigFortuneTableV2
                loading={loading}
                bigFortuneProps={firstResult.bigFortune}
                currentBigFortune={currentBigFortune}
                onClickBigFortune={onClickBigFortune}
              />

              <div className="w-full h-[2px] bg-[#3C3C3C] my-3 md:bg-transparent md:my-1" />

              {/* smallFortune */}
              <SmallFortuneTableV2
                loading={loading}
                currentSmallFortune={currentSmallFortune}
                onClickSmallFortune={onClickSmallFortune}
                hundredList={hundredList}
                currentBigFortune={currentBigFortune}
              />
              <div className="w-full h-[2px] bg-[#3C3C3C] my-3 md:bg-transparent md:my-1" />

              {/* monthFortune */}
              <MonthFortuneTableV2
                loading={loading}
                monthFortune={firstResult.monthFortune}
                currentMonthFortune={currentMonthFortune}
                onClickMonthFortune={setCurrentMonthFortune}
              />
            </div>
            {/* 여성 사주 정보 */}
            <div className="flex flex-col items-center w-1/2">
              <ClientInfoV2
                loading={_loading}
                nickName={_nickName}
                genderType={_genderType}
                birthday={_birthday}
                birthDayType={_birthDayType}
                seasonName={secondResult.seasonName}
                summmerTime={secondResult.summmerTime}
                unknownTime={secondResult.unknownTime}
                solarDate={secondResult.solarBirthDay}
                lunaDate={secondResult.lunarBirthDay}
                age={secondResult.currentAge}
              />

              {/* saju table */}
              <SajuTableV3
                saju={secondResult.saju}
                loading={_loading}
                unknownTime={secondResult.unknownTime}
              />

              <div className="w-full h-[2px] bg-[#3C3C3C] my-3 md:bg-transparent md:my-1" />

              {/* bigfortune */}
              <BigFortuneTableV2
                loading={_loading}
                bigFortuneProps={secondResult.bigFortune}
                currentBigFortune={_currentBigFortune}
                onClickBigFortune={_onClickBigFortune}
              />

              <div className="w-full h-[2px] bg-[#3C3C3C] my-3 md:bg-transparent md:my-1" />

              {/* smallFortune */}
              <SmallFortuneTableV2
                loading={_loading}
                currentSmallFortune={_currentSmallFortune}
                onClickSmallFortune={_onClickSmallFortune}
                hundredList={_hundredList}
                currentBigFortune={_currentBigFortune}
              />

              <div className="w-full h-[2px] bg-[#3C3C3C] my-3 md:bg-transparent md:my-1" />

              {/* monthFortune */}
              <MonthFortuneTableV2
                loading={_loading}
                monthFortune={secondResult.monthFortune}
                currentMonthFortune={_currentMonthFortune}
                onClickMonthFortune={_setCurrentMonthFortune}
              />
            </div>
          </div>
        </div>
      </div>
      {/* 가로모드 우측 빈여백 */}
      {/* <div className="sm:w-[27%] sm:visible" /> */}
    </div>
  );
}
