import { HundredList } from "../../utils/types";
import ShimmerEffect from "../Shimmer";

//궁합보기 페이지
export function SmallFortuneTableV2({
  loading,
  currentSmallFortune,
  hundredList,
  currentBigFortune,
  onClickSmallFortune,
}: {
  loading: boolean;
  currentSmallFortune: number | null;
  hundredList: HundredList[];
  currentBigFortune: number | null;
  onClickSmallFortune: (value: number) => void;
}) {
  const currentHundredList =
    hundredList[currentBigFortune ? currentBigFortune - 1 : 1];

  return (
    <table
      className="w-full overflow-hidden "
      style={{
        fontWeight: 800,
        borderRadius: 6,
        cursor: "pointer",
        borderCollapse: "initial",
      }}
      cellSpacing="0"
    >
      <tbody className="grid w-full grid-cols-1">
        <tr className="bg-[#E2D4FF] col-span-1 grid grid-cols-10 text-[7.7px] sm:text-xs">
          {currentHundredList.year.map((item, index) => {
            const year = item;
            const isCurrentSmallFortune = year === currentSmallFortune;
            if (item === undefined) {
              return (
                <th
                  style={{
                    borderTopLeftRadius: index === 0 ? 6 : 0,
                    borderTopRightRadius: index === 9 ? 6 : 0,
                  }}
                  key={`small-forturne-year-${index}`}
                  className={`col-span-1 border border-t-2 relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                ></th>
              );
            } else
              return (
                <th
                  style={{
                    borderTopLeftRadius: index === 0 ? 6 : 0,
                    borderTopRightRadius: index === 9 ? 6 : 0,
                  }}
                  key={`small-forturne-year-${index}`}
                  className={` col-span-1 aspect-square border border-t-2 relative  border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                  onClick={() =>
                    onClickSmallFortune(Number(currentHundredList.year[index]))
                  }
                >
                  {!loading && (
                    <div className="flex items-center justify-center w-full h-full">
                      {item}
                    </div>
                  )}
                  {isCurrentSmallFortune && (
                    <div
                      style={{
                        border: "2px solid red",
                        borderBottom: "none",
                      }}
                      className="z-[10000] absolute top-0 left-0 w-full h-full "
                    />
                  )}
                </th>
              );
          })}
        </tr>
        <tr className="grid grid-cols-10 col-span-1 text-sm sm:text-xl">
          {currentHundredList.sky.map((item, index) => {
            const year = currentHundredList.year[index];
            const isCurrentSmallFortune = year === currentSmallFortune;
            if (item === undefined) {
              return (
                <td
                  key={`small-forturne-sky-${index}`}
                  className={`overflow-hidden col-span-1 border relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                ></td>
              );
            } else
              return (
                <td
                  key={`small-forturne-sky-${index}`}
                  className={` overflow-hidden col-span-1  border relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                  style={{
                    backgroundColor: !loading ? item.color : "none",
                    color: item.color === "#727272" ? "white" : "black",
                    fontFamily: "KPACJKSC",
                  }}
                  onClick={() =>
                    onClickSmallFortune(Number(currentHundredList.year[index]))
                  }
                >
                  {!loading ? (
                    <div className="flex items-center justify-center w-full h-full">
                      {item.chinese}
                    </div>
                  ) : (
                    <ShimmerEffect />
                  )}
                  {isCurrentSmallFortune && (
                    <div
                      style={{
                        border: "2px solid red",
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                      className="z-[10000] absolute top-0 left-0 w-full h-full "
                    />
                  )}
                </td>
              );
          })}
        </tr>
        <tr className="grid grid-cols-10 col-span-1 text-sm sm:text-xl">
          {currentHundredList.ground.map((item, index) => {
            const year = currentHundredList.year[index];
            const isCurrentSmallFortune = year === currentSmallFortune;
            if (item === undefined) {
              return (
                <td
                  key={`small-forturne-ground-${index}`}
                  className={`overflow-hidden col-span-1 border relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                ></td>
              );
            } else
              return (
                <td
                  key={`small-forturne-ground-${index}`}
                  className={`overflow-hidden col-span-1 border relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                  style={{
                    backgroundColor: !loading ? item.color : "none",
                    color: item.color === "#727272" ? "white" : "black",
                    fontFamily: "KPACJKSC",
                  }}
                  onClick={() =>
                    onClickSmallFortune(Number(currentHundredList.year[index]))
                  }
                >
                  {!loading ? (
                    <div className="flex items-center justify-center w-full h-full">
                      {item.chinese}
                    </div>
                  ) : (
                    <ShimmerEffect />
                  )}
                  {isCurrentSmallFortune && (
                    <div
                      style={{
                        border: "2px solid red",
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                      className="z-[10000] absolute top-0 left-0 w-full h-full "
                    />
                  )}
                </td>
              );
          })}
        </tr>
        <tr className="bg-[#E7E7E7] col-span-1 grid grid-cols-10 text-xs sm:text-xl">
          {currentHundredList.age.map((item, index) => {
            const year = currentHundredList.year[index];
            const isCurrentSmallFortune = year === currentSmallFortune;
            if (item === undefined) {
              return (
                <td
                  style={{
                    borderBottomLeftRadius: index === 0 ? 6 : 0,
                    borderBottomRightRadius: index === 9 ? 6 : 0,
                  }}
                  key={`small-forturne-age-${index}`}
                  className={` col-span-1 border border-b-2 relative aspect-[1.5/1] border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                ></td>
              );
            } else
              return (
                <td
                  style={{
                    borderBottomLeftRadius: index === 0 ? 6 : 0,
                    borderBottomRightRadius: index === 9 ? 6 : 0,
                  }}
                  key={`small-forturne-age-${index}`}
                  className={` col-span-1 border border-b-2 relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                  onClick={() =>
                    onClickSmallFortune(Number(currentHundredList.year[index]))
                  }
                >
                  {!loading && (
                    <div className="flex items-center justify-center w-full h-full">
                      {item}
                    </div>
                  )}
                  {isCurrentSmallFortune && (
                    <div
                      style={{ border: "2px solid red", borderTop: "none" }}
                      className="z-[10000] absolute top-0 left-0 w-full h-full "
                    />
                  )}
                </td>
              );
          })}
        </tr>
      </tbody>
    </table>
  );
}

/** test-api-once 사주보기 페이지*/
export function SmallFortuneTableV3({
  loading,
  currentSmallFortune,
  hundredList,
  currentBigFortune,
  onClickSmallFortune,
}: {
  loading: boolean;
  currentSmallFortune: number | null;
  hundredList: HundredList[];
  currentBigFortune: number | null;
  onClickSmallFortune: (value: number) => void;
}) {
  const currentHundredList =
    hundredList[currentBigFortune ? currentBigFortune - 1 : 1];
  // //console.log(
  //   "hundredList[" + `${currentBigFortune ? currentBigFortune - 1 : 1}` + "]::",
  //   currentHundredList
  // );
  return (
    <table
      className="w-full overflow-hidden "
      style={{
        fontWeight: 800,
        borderRadius: 6,
        cursor: "pointer",
        borderCollapse: "initial",
      }}
      cellSpacing="0"
    >
      <tbody className="grid w-full grid-cols-1">
        <tr className="bg-[#E2D4FF] col-span-1 grid grid-cols-10">
          {currentHundredList.year.map((item, index) => {
            const year = item;
            const isCurrentSmallFortune = year === currentSmallFortune;
            if (item === undefined) {
              return (
                <th
                  style={{
                    borderTopLeftRadius: index === 0 ? 6 : 0,
                    borderTopRightRadius: index === 9 ? 6 : 0,
                  }}
                  key={`small-forturne-year-${index}`}
                  className={`col-span-1 border border-t-2 relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                ></th>
              );
            } else
              return (
                <th
                  style={{
                    borderTopLeftRadius: index === 0 ? 6 : 0,
                    borderTopRightRadius: index === 9 ? 6 : 0,
                  }}
                  key={`small-forturne-year-${index}`}
                  className={` col-span-1 aspect-square border border-t-2 relative  border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                  onClick={() =>
                    onClickSmallFortune(Number(currentHundredList.year[index]))
                  }
                >
                  {!loading && (
                    <div className="flex items-center justify-center w-full h-full sm:text-xs lg:text-sm">
                      {item}
                    </div>
                  )}
                  {isCurrentSmallFortune && (
                    <div
                      style={{
                        border: "2px solid red",
                        borderBottom: "none",
                      }}
                      className="z-[10000] absolute top-0 left-0 w-full h-full "
                    />
                  )}
                </th>
              );
          })}
        </tr>
        <tr className="grid grid-cols-10 col-span-1  max-sm:text-[24px] lg:text-[24px]">
          {currentHundredList.sky.map((item, index) => {
            const year = currentHundredList.year[index];
            const isCurrentSmallFortune = year === currentSmallFortune;
            if (item === undefined) {
              return (
                <td
                  key={`small-forturne-sky-${index}`}
                  className={`overflow-hidden col-span-1 border relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                ></td>
              );
            } else
              return (
                <td
                  key={`small-forturne-sky-${index}`}
                  className={` overflow-hidden col-span-1  border relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                  style={{
                    backgroundColor: !loading ? item.color : "none",
                    color: item.color === "#727272" ? "white" : "black",
                    fontFamily: "KPACJKSC",
                  }}
                  onClick={() =>
                    onClickSmallFortune(Number(currentHundredList.year[index]))
                  }
                >
                  {!loading ? (
                    <div className="flex items-center justify-center w-full h-full">
                      {item.chinese}
                    </div>
                  ) : (
                    <ShimmerEffect />
                  )}
                  {isCurrentSmallFortune && (
                    <div
                      style={{
                        border: "2px solid red",
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                      className="z-[10000] absolute top-0 left-0 w-full h-full "
                    />
                  )}
                </td>
              );
          })}
        </tr>
        <tr className="grid grid-cols-10 col-span-1 max-sm:text-[24px] lg:text-[24px]">
          {currentHundredList.ground.map((item, index) => {
            const year = currentHundredList.year[index];
            const isCurrentSmallFortune = year === currentSmallFortune;
            if (item === undefined) {
              return (
                <td
                  key={`small-forturne-ground-${index}`}
                  className={`overflow-hidden col-span-1 border relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                ></td>
              );
            } else
              return (
                <td
                  key={`small-forturne-ground-${index}`}
                  className={`overflow-hidden col-span-1 border relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                  style={{
                    backgroundColor: !loading ? item.color : "none",
                    color: item.color === "#727272" ? "white" : "black",
                    fontFamily: "KPACJKSC",
                  }}
                  onClick={() =>
                    onClickSmallFortune(Number(currentHundredList.year[index]))
                  }
                >
                  {!loading ? (
                    <div className="flex items-center justify-center w-full h-full">
                      {item.chinese}
                    </div>
                  ) : (
                    <ShimmerEffect />
                  )}
                  {isCurrentSmallFortune && (
                    <div
                      style={{
                        border: "2px solid red",
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                      className="z-[10000] absolute top-0 left-0 w-full h-full "
                    />
                  )}
                </td>
              );
          })}
        </tr>
        <tr className="bg-[#E7E7E7] col-span-1 grid grid-cols-10">
          {currentHundredList.age.map((item, index) => {
            const year = currentHundredList.year[index];
            const isCurrentSmallFortune = year === currentSmallFortune;
            if (item === undefined) {
              return (
                <td
                  style={{
                    borderBottomLeftRadius: index === 0 ? 6 : 0,
                    borderBottomRightRadius: index === 9 ? 6 : 0,
                  }}
                  key={`small-forturne-age-${index}`}
                  className={` col-span-1 border border-b-2 relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                ></td>
              );
            } else
              return (
                <td
                  style={{
                    borderBottomLeftRadius: index === 0 ? 6 : 0,
                    borderBottomRightRadius: index === 9 ? 6 : 0,
                  }}
                  key={`small-forturne-age-${index}`}
                  className={` col-span-1 border border-b-2 relative aspect-square border-[#3C3C3C] ${
                    index === 0
                      ? " border-l-2"
                      : index === 9
                      ? " border-r-2"
                      : " border-x-1 "
                  }`}
                  onClick={() =>
                    onClickSmallFortune(Number(currentHundredList.year[index]))
                  }
                >
                  {!loading && (
                    <div className="flex items-center justify-center w-full h-full ">
                      {item}
                    </div>
                  )}
                  {isCurrentSmallFortune && (
                    <div
                      style={{ border: "2px solid red", borderTop: "none" }}
                      className="z-[10000] absolute top-0 left-0 w-full h-full "
                    />
                  )}
                </td>
              );
          })}
        </tr>
      </tbody>
    </table>
  );
}
