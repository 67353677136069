import React, { createContext, useEffect, useState } from "react";

import { getItem, loginTokenKey } from "../utils/localStorage";

type AuthState = {
  authState: boolean;
  handleChangeAuthState: Function;
};

export const AuthStateContext = createContext<AuthState>({
  authState: false,
  handleChangeAuthState: () => {},
});

export const AuthStateContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [authState, setAuthState] = useState<boolean>(false);
  const access_token = getItem(loginTokenKey);

  const handleChangeAuthState = (value: boolean) => {
    setAuthState(value);
  };
  useEffect(() => {
    if (access_token) {
      setAuthState(true);
    }
  }, [access_token]);
  const value = {
    authState,
    handleChangeAuthState,
  };

  return (
    <AuthStateContext.Provider value={value}>
      {children}
    </AuthStateContext.Provider>
  );
};
