import { Calender, CalenderMonth } from "../utils/types";
import { format, isSameDay } from "date-fns";
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek } from "date-fns";
import { isSameMonth, addDays } from "date-fns";
import { convertLunaDate, parseDateString } from "../utils/timeConvert";
import ShimmerEffect from "./Shimmer";

interface ICalendarProps {
  loading: boolean;
  calenderYear: string;
  calenderMonth: CalenderMonth[];
}

export function Calendar({
  calenderYear,
  calenderMonth,
  loading,
}: ICalendarProps) {
  const currentMonth = parseDateString(calenderYear);

  // //console.log("Calendar->calenderMonth::", calenderMonth);
  return (
    <table
      className="grid w-full grid-cols-1 overflow-hidden"
      style={{
        fontWeight: 800,
        borderRadius: 6,
        borderStyle: "hidden",
        padding: "0px",
        margin: "0px",
        boxShadow: "0 0 0 2px #3C3C3C",
      }}
    >
      <RenderDays />
      <RenderCells
        loading={loading}
        data={calenderMonth}
        currentMonth={currentMonth}
      />
    </table>
  );
}

export function RenderDays() {
  const date = ["일", "월", "화", "수", "목", "금", "토"];

  return (
    <thead>
      <tr className="grid grid-cols-7 bg-[#E2D4FF]">
        {date.map((item, index) => {
          return (
            <th
              key={`render-days-${index}`}
              className="col-span-1 font-bold border border-[#3C3C3C] flex items-center justify-center w-full h-full"
            >
              {item}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

function RenderCells({
  loading,
  data,
  currentMonth,
}: {
  loading: boolean;
  data: CalenderMonth[];
  currentMonth: Date;
}) {
  // //console.log("Calendar -> RenderCells -> data::", data);
  let dateCell = [...data];

  // currentMonth = 2023.08

  // monthStart = Tue Aug 01 2023  2023.08.01 화요일
  const monthStart = startOfMonth(currentMonth);
  // //console.log("monthStart::", monthStart);

  // monthEnd =  Thu Aug 31 2023  2023.08.31 목요일
  const monthEnd = endOfMonth(monthStart);
  // //console.log("monthEnd::", monthEnd);

  // startDate =  Sun Jul 30 2023  2023.07.30 일요일이 해당 월의 첫째주의 시작날짜.
  const startDate = startOfWeek(monthStart);
  // //console.log("startDate::", startDate);

  // endDate = Sat Sep 02 2023 2023.09.02 토요일이 해당월의 마지막주 마지막날짜.
  const endDate = endOfWeek(monthEnd);
  // //console.log("endDate::", endDate);

  const rows: React.ReactNode[] = [];
  let days = [];
  let formattedDate = "";

  //1. dateCell의 앞뒤로 monthStart 만큼, monthEnd만큼 객체를 채워준다.
  let prevMonthDate = startDate;

  while (prevMonthDate < monthStart) {
    let obj = createCalendarMonthObj(prevMonthDate);
    dateCell = [obj, ...dateCell];
    prevMonthDate = addDays(prevMonthDate, 1);
  }

  let nextMonthDate = addDays(monthEnd, 1);

  while (nextMonthDate <= endDate) {
    let obj = createCalendarMonthObj(nextMonthDate);
    dateCell = [...dateCell, obj];
    nextMonthDate = addDays(nextMonthDate, 1);
  }
  //2. 해당 데이터를 하나씩 pop하면서 한주씩 채워간다.
  //3. dateCell의 배열의 길이가 0일때까지 반복한다.
  // //console.log("===============================================");
  // //console.log("dateCell::", dateCell);

  let rownumer = 0;
  while (dateCell.length) {
    for (let i = 0; i < 7; i++) {
      //formattedDate = format(day, "d");
      let currentObj = dateCell.shift() as CalenderMonth;
      // //console.log(new Date(currentObj.solarDate));
      const solarDateISO = currentObj.solarDate.replace(/\./g, "-");
      formattedDate = format(new Date(solarDateISO), "d");
      days.push(
        <td
          key={`render-cells-${i}-${currentObj.solarDate}`}
          className={`col-span-1 p-0 m-0 overflow-hidden border border-black aspect-square relative ${
            isSameDay(new Date(solarDateISO), new Date()) ? " bg-red-200 " : ""
          }`}
        >
          {loading ? (
            <ShimmerEffect />
          ) : (
            <div
              className={`w-full h-full grid grid-row-2 grid-cols-2 p-[3px] sm:p-0 lg:p-[2px] ${
                !isSameMonth(new Date(solarDateISO), monthStart)
                  ? " invisible "
                  : " visible "
              }`}
            >
              <span
                className="grid grid-cols-1 col-span-1 row-span-1 p-0 m-0"
                //   holiday === 1
                //   // 토요일인 경우
                //       // 파란색
                //       e.color = '#6BA3FF'

                // holiday === 2
                //  // 일요일인 경우
                //       // 빨간색
                //       e.color = '#FF6B73'

                // isHoliday === 1 -> 공휴일
                // e.color = '#FF6B73

                // 나머지 'black'
                style={{
                  fontWeight: 800,
                  color:
                    currentObj.isHoliday === 1 || currentObj.holiday === 2
                      ? "#FF6B73" // 공휴일이거나 일요일인 경우 빨간색
                      : currentObj.holiday === 1
                      ? "#6BA3FF" // 토요일인 경우 파란색
                      : "black", // 나머지 경우 검정색
                  lineHeight: "100%",
                }}
              >
                <span className="col-span-1">{formattedDate}</span>
                <span
                  className="col-span-1 text-xs sm:text-[10px] text-black"
                  style={{
                    lineHeight: "70%",
                  }}
                >
                  {currentObj.season ?? ""}
                </span>
              </span>

              <div
                className="col-span-1 row-span-1 text-[13px] text-right"
                style={{
                  lineHeight: "100%",
                  fontFamily: "KPACJKSC",
                }}
              >
                <p>{currentObj.daySky}</p>
                <p>{currentObj.dayGround}</p>
              </div>
              <div
                className="flex items-end justify-end col-span-2 row-span-1"
                style={{ lineHeight: "100%" }}
              >
                {currentObj.dateName ? (
                  <span
                    className={
                      "text-[10px] sm:text-[6px] lg:text-[10px]" +
                      `${currentObj.dateName.length > 5 && " lg:text-[7px]"}`
                    }
                    style={{
                      color:
                        currentObj.holiday === 2 || currentObj.isHoliday === 1
                          ? "#FF6B73"
                          : "black",
                    }}
                  >
                    {currentObj.dateName}
                  </span>
                ) : (
                  <span className="text-lg sm:text-sm text-slate-400">
                    {currentObj.lunarDate
                      ? convertLunaDate(currentObj.lunarDate)
                      : ""}
                  </span>
                )}
              </div>
            </div>
          )}
        </td>
      );
    }
    rownumer += 1;
    rows.push(
      <tr key={`week-${rownumer}`} className="grid grid-cols-7 col-span-1">
        {days}
      </tr>
    );
    days = [];
  }

  return <tbody className="grid w-full grid-cols-1">{rows}</tbody>;
}
function createCalendarMonthObj(date: Date): CalenderMonth {
  return {
    solarDate: date.toDateString(),
    lunarDate: date.toDateString(),
    season: "string",
    daySky: "string",
    dayGround: "string",
    dateName: "string",
    holiday: 0,
    isHoliday: 0,
    // color: "",
  };
}
